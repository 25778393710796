.referrals {
  padding-top: 9px;
  max-height: 375px;
  overflow: auto;

  &__count {
    position: absolute;
    right: 24px;
    top: 24px;

    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #E4E4E4;
  }

  &__table {
    border-collapse: collapse;
    font-size: 16px;
    line-height: 133%;
    color: #E4E4E4;

    td {
      border-bottom: 0.5px solid rgba(59, 59, 72, 0.48);
      padding: 13px 6px 6px 6px;

      &:first-child {
        width: 68px;
      }
    }

    tr:last-child > td {
      border-bottom: none;
    }
  }
}