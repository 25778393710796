.affiliate-modal-auth-success {
    padding: 20px 20px 14px 20px;
    text-align: center;

    &__title {
        padding-top: 20px;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;

        text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
    }
    &__description {
        padding-top: 8px;
        font-size: 20px;
        line-height: 24px;

        text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
    }

    &__invited-by {
        font-size: 14px;
        line-height: 16px;
        color: #ACACAC;
        padding-top: 8px;
    }

    &__privileges{
        width: 100%;
        margin-top: 20px;
        background: rgba(85, 80, 56, 0.2);
        padding: 12px;

        font-size: 14px;
        line-height: 16px;
        text-align: left;

        &__content {
            padding-top: 4px;
            color: #ACACAC;
        }
    }

    &__button {
        width: 100%;
        margin: 20px auto 16px auto;
    }
}