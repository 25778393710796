.stats_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 4rem;

    @media screen and (max-width: $tableWidth) {
        margin-top: 9px;
        padding: 0 16px;
        font-weight: 400;

        .btn {
            display: none;
        }

        .user_info {
            margin-bottom: 10px;
        }
    }

    .user_info {
        &__name {
            font-size: px(16);
            color: $mainFontColor;
            margin-bottom: 14px;
        }

        &_stats {
            font-size: px(16);
        }

        .wax_price {
            margin-left: 32px;
        }

        .dmp_price {
            margin-right: 26px;

            @media screen and (max-width: $smPhoneWidth) {
                margin-right: 26px;
            }
        }

        .wax_price {
            margin-left: 0;
        }

        .dmp_price, .wax_price {
            font-size: px(16);

            .icon {

                width: 26px;
                height: 26px;

                @media screen and (max-width: 1920px) {
                    width: 18px;
                    height: 18px;
                }
                
                margin-right: 5px;
                margin-bottom: 2px;

                svg {
                    width: 100%;
                    height: auto;
                }
            }

            .sum {
                font-size: px(16);
                font-weight: 600;
            }
        }
    }

    padding-bottom: 13px;
    border-bottom: 1px solid rgba(245, 223, 77, 0.2);

    .logout_img {
        margin-right: 10px;
        stroke: $mainFontColor; 
    }

    .btn {
        font-size: px(12);
    }
}