.auction-page {
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $tableWidth) {
        background: rgba(77, 103, 114, 0.2);
    }

    &__top {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        padding: 12px 0;
        background: url('../../img/bg/status-bar-bg.svg') 0 0 / 100% 100%
            no-repeat;

        @media screen and (max-width: $tableWidth) {
            background: none;

            & .dm-tab {
                width: 148px;
            }
        }
    }

    &:not(.auction-page--inner) {
        .auction-page__content {
            &-left-bg,
            &-right-bg {
                position: absolute;
                background: #000;
                height: 460px;
                top: 0;
                z-index: -1;

                @media screen and (max-width: $tableWidth) {
                    display: none;
                }
            }

            &-left-bg {
                left: 0;
                width: 199px;
                background: url('../../img/bg/main-content-inner-left-bg.svg') 0
                    0 / cover no-repeat;
            }

            &-right-bg {
                right: 0;
                width: 186px;
                background: url('../../img/bg/main-content-inner-right-bg.svg')
                    100% 0 / cover no-repeat;
            }

            &::after,
            &::before {
                content: '';
                position: absolute;
                background: rgba(97, 157, 183, 0.1);
                z-index: -1;

                @media screen and (max-width: $tableWidth) {
                    content: none;
                }
            }

            &::before {
                top: 18px;
                left: 199px;
                right: 186px;
                height: 442px;
            }

            &::after {
                left: 10px;
                right: 0;
                bottom: 0;
                height: calc(100% - 460px);
            }
        }
    }

    &__content {
        position: relative;
        height: 100%;
        min-height: 0;
        display: flex;
        flex-direction: column;
        padding: 0 28px 28px 42px;
        margin-left: -10px;
        margin-top: -10px;

        @media screen and (max-width: $tableWidth) {
            padding: 0 6px 5px;
            margin: 0;
        }
    }

    &__list {
        height: 100%;
        overflow: auto;
    }

    &__filters {
        margin-bottom: 20px;
        padding: 5px 0 16px;
        border-bottom: 1px solid #3f5761;

        @media screen and (max-width: $tableWidth) {
            margin: 0 -5px 5px;
            padding: 0 12px 8px;
        }
    }

    &__button-other-lots {
        margin-top: 12px;
    }
}

.auction-filters {
    &__label {
        margin-bottom: 10px;

        @media screen and (max-width: $tableWidth) {
            display: none;
        }
    }
}

.auction-page-sidebar-stats {
    @media screen and (max-width: $tableWidth) {
        position: fixed;
        width: 100%;
        top: 30px;
        right: 0;
        bottom: 0;
        left: 0;
        background: black;
        z-index: 1000;
        padding: 40px 24px;
        border: none;
        transform: translateY(-100%);
        transition: transform 0.2s ease-in;

        &.opened {
            transform: translateY(0);
        }
    }
    &__close {
        display: none;
        background: none;
        border: none;
        color: $mainFontColor;
        font-size: 16px;
        position: absolute;
        right: 24px;
        top: 20px;

        @media screen and (max-width: $tableWidth) {
            display: block;
        }
    }
    &__title {
        font-weight: 700;
        line-height: 20px;
        font-size: 16px;
    }
    &__item {
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__rarities {
        margin-bottom: 46px;
    }
    &__total {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 12px 8px;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;

        &-icon {
            width: 16px;
            height: 16px;
        }
    }
}

.auction-page-loader {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $mainFontColor;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
}

.auction-page-dont-have-auctions {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__title {
        color: $mainFontColor;
        max-width: 240px;
        font-weight: 800;
        font-size: 24px;
        line-height: 28px;
        margin-top: 22px;
        margin-bottom: 18px;
    }

    &__link {
        cursor: pointer;
        color: $mainFontColor;

        &:hover {
            border-bottom: 1px dashed currentColor;
        }
    }
}

.auction-asset-page {
    &-content {
        overflow-y: auto;
        margin-top: 28px;
        width: 100%;
        display: flex;
        gap: 48px;
        height: 100%;

        @media screen and (max-width: $tableWidth) {
            flex-direction: column;
            align-items: center;
        }
    }

    &-info {
        padding: 24px 20px;
        max-width: 220px;
        background: rgba(63, 87, 97, 0.2);

        @media screen and (max-width: $tableWidth) {
            max-width: 450px;
        }

        &__title {
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 12px;
        }
    }

    &-options {
        max-width: 480px;
        flex-grow: 1;

        &-main {
            display: grid;
            grid-template-columns: max-content max-content max-content;
            gap: 20px 28px;
            padding-bottom: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid rgba(63, 87, 97, 0.2);

            @media screen and (max-width: $tableWidth) {
                grid-template-columns: 1fr 1fr;
            }
        }

        &-cell {
            display: flex;
            flex-direction: column;
            gap: 4px;

            &__title {
                color: #acacac;
                font-size: 14px;
                line-height: 16px;
            }

            &__value {
                display: flex;
                align-items: center;
                gap: 4px;
                font-size: 16px;
                line-height: 20px;
            }
        }

        &-current-bid {
            grid-area: 3 / 1;

            &__text {
                &.winning {
                    color: $accentColor;
                }

                &.losing {
                    color: #f78e1d;
                }
            }
        }

        &-form {
            display: grid;
            row-gap: 16px;
            margin-bottom: 16px;
        }

        &__notice {
            background: rgba(63, 87, 97, 0.2);
            padding: 12px 16px;
            font-size: 14px;
            line-height: 16px;
        }

        &-last-bids {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-top: 16px;
            padding-top: 28px;
            border-top: 1px solid rgba(63, 87, 97, 0.2);

            &__title {
                color: #acacac;
                font-size: 16px;
                line-height: 20px;
            }

            &-table {
                font-size: 14px;
                line-height: 16px;

                th,
                td {
                    padding: 12px 0;
                    background: rgba(63, 87, 97, 0.2);

                    &:first-child {
                        padding-left: 16px;
                    }
                }

                th {
                    backdrop-filter: blur(15px);
                }

                tr:first-child td {
                    padding-top: 20px;
                }

                tr[data-wining='true'] td {
                    color: #fffad8;
                    font-weight: 600;
                }

                tr[data-losing='true'] td {
                    color: #f56b4d;
                }

                tr td:last-child a {
                    color: $mainFontColor;
                    font-weight: 400;
                }

                & tr &__empty {
                    padding: 24px 0;
                    text-align: center;
                    color: #acacac !important;
                    backdrop-filter: blur(15px);
                }
            }
        }
    }

    &__card {
        cursor: auto;

        &::before,
        &::after {
            content: none;
        }
    }

    &-description {
        margin-top: 24px;

        &__title {
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 8px;
        }

        &__text {
            font-size: 12px;
            line-height: 16px;
            color: #acacac;
        }
    }
}
