.dm-select {
    position: relative;
    width: max-content;
    color: $primaryTextColor;

    &-trigger {
        width: inherit;
        color: inherit;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(245, 223, 77, 0.1);
        border: 1px solid $darkYellow;

        & svg {
            width: 12px;
            height: 12px;
            margin-left: 10px;
        }

        &.open {
            background-color: #000;
            color: #000;
            border-bottom: none;
        }
    }

    &-options {
        border: 1px solid #968e53;
        border-top: none;
        position: absolute;
        z-index: 1000;
        width: inherit;
    }

    &-option {
        cursor: pointer;
        background-color: #000;

        &.active {
            color: $mainFontColor;
        }

        &.selected {
            background-color: #2b291b;
            color: $mainFontColor;
        }
    }

    &.size-m {
        font-size: 14px;

        .dm-select-trigger {
            padding: 8px 12px;
        }

        .dm-select-option {
            padding: 8px 12px;
        }
    }
}
