.dm-link {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: $mainFontColor;

    &--active {
        border-bottom: 1px solid currentColor;
    }

    &:hover {
        border-bottom: 1px solid currentColor;
    }
}
