.show-btn {
    position: absolute;
    transition: all 0.2s ease-in;
    cursor: pointer;
    width: 383px;
    left: calc(50% - 87px);
    top: 178px;
    transform: translate(-50%);

    img {
        height: auto;
        width: 100%;
    }

    &:hover {
        transform: translateX(-50%) scale(1.1);
    }

    @media screen and (max-width: $tableWidth) {
        width: 350px;
    }

    &.rewards {
        &.big_element {
            visibility: visible;
        }

        &.phone_element {
            visibility: hidden;
        }

        @media screen and (max-width: $tableWidth) {
            &.big_element {
                visibility: hidden;
            }

            &.phone_element {
                visibility: visible;
            }
        }

        height: auto;
        width: 16.2vw;
        top: unset;
        bottom: 12.5vh;
        transform: translate(-50%, 0);
        left: calc(30% + 31vw);

        &:hover {
            transform: translate(-50%, 0) scale(1.1);
        }

        @media screen and (max-width: 2660px) {
            width: 15vw;
            left: calc(23% + 31vw);
        }

        @media screen and (max-width: 1920px) {
            width: 14vw;
            left: calc(23% + 31vw);
        }

        @media screen and (max-width: 1440px) {
            left: calc(20% + 31vw);
        }

        @media screen and (max-width: 1366px) {
            width: 14vw;
            left: calc(20% + 29vw);
            bottom: 2vh;
        }

        @media screen and (max-width: 1024px) {
            width: 15vw;
            left: calc(20% + 30vw);
        }

        img {
            width: 100%;
            height: auto;
        }

        @media screen and (max-width: $tableWidth) {
            width: 100px;
            left: calc(50% - -9rem);
        }
    }

    & &__button {
        position: absolute;
        bottom: 30%;
        left: 50%;
        width: 142px;
        transform: translateX(-48%);
    }
}
