.dm-radio-group {
    display: flex;
    align-items: center;
    gap: 16px;

    &__items {
        display: flex;
        gap: 12px;
    }

    &__label {
        font-size: 12px;
        line-height: 16px;
        color: $primaryTextColor;
    }
}
