.rewards_popup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(17, 16, 10, .8);
    padding: 20px 16px;
    backdrop-filter: blur(6px);
    z-index: 100;
    transform: translateY(-100%);
    transition: transform .2s ease-in;

    .rewards_container {
        width: 100%;
        height: 90vh;
        overflow-y: auto;
    }

    &.active {
        transform: translateY(0);
    }

    .close_modal {
        display: flex;

        button {
            margin-left: auto;
            background: none;
            border: none;
            color: $mainFontColor;
            font-size: 16px;

            span {
                font-weight: 800;
                font-size: 17px;
            }
        }
    }

    .block_title {
        font-size: 16px;
        letter-spacing: 0.05em;
        margin-bottom: 20px;
    }

    .postscript {
        font-size: px(14);
    }

    .information_item {
        padding: 16px 0;
        font-size: 12px;

        &.indent {
            margin-top: 77px;
            border-top: none;
        }
    }

    .button_block {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-right: 8px;
        font-size: 12px;
        // .btn {
        //     margin-left: auto;
        // }
    }

    .referal_link {
        margin-top: 57px;
    }

    .block_link {
        position: relative;
        z-index: 69;
        margin: 19px 0;
        font-size: 10px;
        display: flex;
        align-items: center;

        button {
            cursor: pointer;
            background: none;
            border: none;
        }
        a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 62%;
            display: block;
            margin-right: 10px;
        }
    }

}