.unauthorized_content {
    width: 100%;
    margin-top: 1.5rem;
    color: $primaryTextColor;
    font-size: px(12);
    line-height: 14px;
    overflow-y: auto;

    @media screen and (max-width: $tableWidth) {
        margin-top: 0;
        font-size: px(18);
        line-height: 21px;
    }

    .unauthorized_wrapper {
        height: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.information__container {
    max-width: 480px;
    margin-right: 160px;

    @media screen and (max-width: $tableWidth) {
        width: 100%;
        margin-right: 0;
        padding: 10px;
    }
}

.connect_wallet {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 50px;
    padding-right: 230px;
    margin-bottom: 30px;
    background-image: url('../../img/bg/wax-wallet-bg.svg');
    background-repeat: no-repeat;
    background-position: 0 50%;

    @media screen and (max-width: $tableWidth) {
        display: none;
    }

    &__title {
        color: $mainFontColor;
        font-weight: 600;
        font-size: px(24);
        line-height: 28px;
        margin: -7px 0 10px;
        text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
    }

    &__text {
        text-align: center;
        color: #acacac;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        margin-bottom: 19px;
    }

    &__btn {
        width: 240px;

        svg {
            width: 24px;
            height: 24px;
            margin-right: 4px;
        }
    }
}

.welcome-info {
    &__title {
        margin: 19px;
        font-family: $mainFont;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        text-align: center;
        color: #e4e4e4;
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        & + & {
            margin-top: 12px;
        }
    }

    &__connect-wax {
        color: $accentColor;
        border-bottom: 1px solid currentColor;
        cursor: pointer;
    }

    &__guide {
        margin-top: 24px;
        padding: 16px;
        background: rgba(63, 87, 97, 0.2);
    }

    &__guide-title {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #e4e4e4;
    }

    &__guide-list {
        padding-left: 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #acacac;

        li {
            list-style-type: numeric;
        }
    }
}
