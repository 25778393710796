.achievement-modal {
    padding: 20px 20px 14px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #E4E4E4;
    position: relative;

    .affiliate-modal__title {
        padding-top: 12px;
    }

    &__privileges, &__steps {
        width: 100%;
        margin-top: 12px;
        background: rgba(85, 80, 56, 0.2);
        padding: 12px;

        font-size: 14px;
        line-height: 16px;

        &__title {
            font-weight: 700;
        }

        &__content {
            padding-top: 4px;
            color: #ACACAC;
        }
    }

    &__steps {
        margin-bottom: 24px;

        &__content {
            padding-left: 20px;
            li {
                list-style-type: decimal;
            }
        }
    }

    &__image-container {
        position: relative;
        height: 257px;
    }

    &__badge {
        position: absolute;
        width: 36px;
        height: 36px;
        top: -10px;
        right: -18px;

        display: flex;
        align-items: center;
        justify-content: center;
        background: #F5DF4D;
        border-radius: 20px;
        z-index: 2;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    &__unlock-text {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        padding: 4px 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #F5DF4D;

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 20px;
            background: #F5DF4D;
            width: 12px;
            height: 12px;
            margin-left: 5px;

            svg {
                width: 8px;
                height: 8px;
            }
        }
    }

    &__button {
        width: 100%;
        height: 36px;
        margin-bottom: 20px;
        margin-top: 24px;
    }

    &--done {
        .achievement-modal__privileges {
            margin-bottom: 56px;
        }
    }
}