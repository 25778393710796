.dm-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $mainBorderColor;
    border-color: $darkYellow;

    &-circle {
        height: 100%;
        background-color: #acacac;
        border-color: inherit;
        border-radius: 50%;
        transition: transform 0.2s ease-in-out, background-color 0.3s ease-in-out;

        &.enabled {
            background-color: $mainFontColor;
        }
    }

    &.size-m {
        width: 36px;
        height: 20px;
        border-width: 1px;
        border-style: solid;
        border-radius: 100px;

        .dm-switch-circle {
            width: 20px;
            height: inherit;
            border-width: inherit;
            border-style: inherit;
            transform: translateX(-8px);

            &.enabled {
                transform: translateX(8px);
            }
        }
    }
}
