.menu_block {
    overflow: visible;
}

.faq_list {
    width: 100%;
    margin-top: 100px;
    color: $primaryTextColor;
    line-height: 14px;
    height: 60vh;

    overflow-y: scroll;

    @media screen and (max-width: 1024px) {
        margin-top: 0;
    }

    @media screen and (max-width: 1536px) {
        height: 54vh;
    }

    @media screen and (max-width: 1440px) {
        height: 54vh;
    }

    @media screen and (max-width: 1366px) {
        height: 65vh;
    }

    @media screen and (max-width: $tableWidth) {
        height: 52vh;
    }

    @media screen and (max-width: $tableWidth) and (max-height: 553px) {
        height: 44vh;
    }

    @media screen and (max-width: 375px) and (max-height: 555px) {
        height: 42vh;
    }

    @media screen and (max-width: $smPhoneWidth) {
        height: 49vh;
    }

    .faq__container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &_wrap {
        height: max-content;
        display: flex;
        justify-content: center;
        // align-items: center;
        flex-direction: column;
        padding-top: 20px;
        width: 70%;

        @media screen and (max-width: $tableWidth) {
            width: 100%;
        }
    }
    .faq_item {
        margin-bottom: 50px;
        width: 100%;
        overflow: hidden;

        @media screen and (max-width: 1920px) {
            margin-bottom: 32px;
        }

        @media screen and (max-width: $tableWidth) {
            margin-bottom: 0;
        }

        &__title {
            font-weight: 700;
            font-size: px(16);
            line-height: 20px;
            margin-bottom: 16px;
            position: relative;
            padding-left: 24px;
            cursor: pointer;
            letter-spacing: 0.04em;
            color: $primaryTextColor;
            overflow: initial;

            &.active,
            &.big {
                font-size: px(16);

                @media screen and (max-width: $tableWidth) {
                    font-size: 12px;
                }
            }

            @media screen and (max-width: $tableWidth) {
                font-size: 12px;
            }

            &::before {
                content: url('../../img/icons/arrow_bot.svg');
                opacity: 1;
                visibility: visible;
                position: absolute;
                left: 3px;
                top: calc(50% - 24px);
                transform: scale(2);
                transition: transform 0.2s ease-in;

                @media screen and (max-width: 1920px) {
                    top: calc(50% - 13px);
                    transform: scale(1.2);
                }

                @media screen and (max-width: 1440px) {
                    top: calc(50% - 12px);
                }

                @media screen and (max-width: $tableWidth) {
                    top: calc(50% - 8px);
                }
            }

            &.active::before {
                transform: translateY(10px) rotate(180deg) scale(2);

                @media screen and (max-width: 1920px) {
                    transform: rotate(180deg) scale(1.2);
                }
            }
        }

        &__content {
            max-height: 0;
            opacity: 0;
            visibility: hidden;
            font-weight: 400;
            transition: all 0.3s ease;
            font-size: px(14);
            padding-left: 25px;
            line-height: 16px;

            @media screen and (max-width: 2660px) {
                line-height: 140%;
            }

            @media screen and (max-width: $tableWidth) {
                font-size: 12px;
                line-height: 102.2%;
            }

            &.active {
                max-height: 100%;
                opacity: 1;
                visibility: visible;

                @media screen and (max-width: $tableWidth) {
                    padding-left: 20px;
                    line-height: 102.2%;
                    padding-bottom: 10px;
                }
            }

            .list_title {
                font-weight: 700;
                font-size: px(14);
                line-height: 140%;
                margin-top: 25px;

                &:first-child {
                    margin-top: 0;
                }
            }

            .list {
                padding-left: 24px;

                a {
                    color: $mainFontColor;
                    text-decoration: underline;
                    padding-bottom: 6px;
                    line-height: 140%;
                }

                ul li {
                    list-style: disc;
                    line-height: 140%;
                }

                &.numeric {
                    ul li {
                        list-style: all;
                    }
                }
            }

            .faq_table {
                @media screen and (max-width: $tableWidth) {
                    overflow-x: scroll;
                }
            }

            .faq_table__wrap {
                margin-bottom: 40px;
                padding-left: 30px;

                @media screen and (max-width: $tableWidth) {
                    padding-left: 5px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .title {
                    font-weight: bold;
                    line-height: 16px;
                    font-size: px(14px);
                    color: $mainFontColor;
                    margin-bottom: 8px;
                }

                .table_title {
                    font-size: px(12);
                    font-weight: 500;
                    text-transform: uppercase;
                    color: $primaryTextColor;
                    margin-bottom: 16px;
                    padding-top: 16px;

                    &:first-child {
                        padding-top: 0;
                    }
                }

                .faq_table__list {
                    display: grid;
                    grid-template-columns: 25% 15% 18% 13% 13% 15%;
                    color: $primaryTextColor;
                    font-size: px(12);

                    padding-bottom: 16px;
                    border-bottom: 0.5px solid rgba(245, 223, 77, 0.2);

                    @media screen and (max-width: $tableWidth) {
                        width: 140vw;
                    }

                    &.last {
                        border-bottom: none;
                    }

                    .item {
                        white-space: nowrap;
                        margin-bottom: 5px;

                        &.head {
                            font-weight: 500;
                        }
                    }

                    .item__text {
                        margin-left: 4px;
                    }
                }
            }

            .note_block {
                font-size: px(14);
                line-height: 140%;
                color: #fffad8;
                padding-left: 30px;

                @media screen and (max-width: $tableWidth) {
                    padding-left: 5px;
                }
            }
        }
    }
}
