@import '../../assets/scss/Utils/vars.scss';

.cookies-frame {
    position: fixed;
    bottom: 12px;
    right: 13px;
    padding: 8px 8px 8px 12px;
    border: 2px solid #555038;
    align-items: center;
    display: inline-flex;
    background: black;

    @media screen and (max-width: $tableWidth) {
        bottom: 80px;
        display: block;
        left: 12px;
    }
    // перекрывает карточки в сторе
    z-index: 1000;
}

.cookies-notificator-text {
    margin-right: 74px;

    @media screen and (max-width: $tableWidth) {
        margin-right: 0;
        margin-bottom: 24px;
    }
}

.cookies-notificator-button {
    width: 221px;

    @media screen and (max-width: $tableWidth) {
        width: 100%;
    }
}