.sidebar-wrapper {
    width: 218px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-top: -30px;
    padding-bottom: 40px;

    @media screen and (max-width: $tableWidth) {
        display: none;
    }

    .sidebar_block {
        padding: 25px 10px;
        background: rgba(85, 80, 56, 0.2);
        border: 2px solid #555038;
        box-sizing: border-box;
        backdrop-filter: blur(15px);

        position: relative;
        &.hide {
            display: none;
        }
    }

    &.staking_sidebar {
        .earned_block {
            .information_value {
                justify-content: flex-start;
                align-items: flex-start;
            }
        }
    }
}

.block_title {
    font-weight: 600;
    font-size: px(16);
    letter-spacing: 0.05em;
    text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
    margin-bottom: 25px;
}

.block_information {
    .information_item {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
        font-size: px(12);
        padding: 15px 0;
        border-bottom: 1px solid #555038;
        border-top: 1px solid #555038;
        position: relative;

        .information__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-top: 0;
            max-height: 0;
            overflow: hidden;

            transition: all 0.3s ease-in;

            &.show {
                max-height: 30vh;
                padding-top: 16px;
            }

            .item {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1.1vh;

                .value {
                    display: flex;
                    padding-right: 30px;

                    @media screen and (max-width: 1920px) {
                        padding-right: 20px;
                    }

                    @media screen and (max-width: 1600px) {
                        padding-right: 12px;
                    }

                    div {
                        margin-right: 4px;
                    }
                }
            }
        }

        &.slide {
            cursor: pointer;
        }

        &.show {
            .information_value.information__info {
                &::after {
                    transform: translateY(-54%) scale(2) rotate(-180deg);

                    @media screen and (max-width: 1920px) {
                        transform: translateY(-54%) scale(1.2) rotate(-180deg);
                    }
                }
            }
        }

        .information_value {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;

            .postscript {
                font-size: px(8);
                color: #aa9710;
                opacity: 0.4;
            }

            &.information__info {
                padding-right: 30px;
                position: relative;

                @media screen and (max-width: 1920px) {
                    padding-right: 20px;
                }

                @media screen and (max-width: 1600px) {
                    padding-right: 12px;
                }

                &::after {
                    content: url('../../img/icons/arrow.svg');
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-64%) scale(2);

                    @media screen and (max-width: 1920px) {
                        transform: translateY(-54%) scale(1.2);
                    }
                }
            }
        }

        @media screen and (max-width: 2048px) {
            padding: 10px 0;
        }

        @media screen and (max-width: 1920px) {
            padding: 15px 0;
        }

        @media screen and (max-width: 1440px) {
            padding: 8px 0;
        }

        @media screen and (max-width: 1366px) {
            padding: 10px 0;
        }

        .information_title {
            display: flex;
            align-items: center;
            position: relative;
            flex-wrap: wrap;
            font-size: px(12);

            .value {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                font-size: px(12);
                letter-spacing: 0.1em;
                font-weight: 600;
                margin-top: 8px;
                align-items: flex-end;

                span {
                    line-height: 100%;
                }

                .icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;

                    @media screen and (max-width: 1920px) {
                        width: 14px;
                        height: 14px;
                    }

                    svg {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            .info_block {
                z-index: 999;
            }

            .info_popup {
                display: none;
                position: absolute;
                top: 160%;
                left: 0;
                width: 200px;
                height: 70px;
                background: #000;
                z-index: 9999;
                box-sizing: border-box;
                padding: 12px 12px;
                font-size: 12px;
                color: #f5df4d;
                line-height: 1.2;
                box-shadow: 0px 2px 10px rgba(245, 223, 77, 0.3);
                @media screen and (max-width: $tableWidth) {
                    left: 28px;
                    top: 42px;
                }

                &.show {
                    display: block;
                }

                &::before {
                    content: '';
                    position: absolute;
                    border: 32px solid transparent;
                    border-bottom: 23px solid rgb(0, 0, 0);
                    display: block;
                    width: 0;
                    height: 0;
                    top: -77%;
                    left: 0;
                    filter: drop-shadow(0px 2px 10px rgba(245, 223, 77, 0.3));
                    //box-shadow: 0px 2px 10px rgba(245, 223, 77, 0.3);
                    z-index: 9998;
                    mix-blend-mode: overlay;
                }
            }

            .title {
                position: relative;
            }

            .info_block {
                position: absolute;
                top: -6px;
                right: -30px;
                width: 20px;
                height: 20px;
                justify-content: center;
                align-items: center;
                display: flex;

                @media screen and (max-width: 1920px) {
                    width: 14px;
                    height: 14px;
                    right: -20px;
                }

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .postscript {
            color: #aa9710;
            font-size: px(10);
        }

        .info_popup {
            display: none;
            position: absolute;
            top: 70px;
            left: 0;
            width: 95%;
            background: #000;
            z-index: 9999;
            box-sizing: border-box;
            padding: 12px 12px 12px 12px;
            font-size: 12px;
            line-height: 1.2;
            color: #f5df4d;
            line-height: 12px;
            // filter: drop-shadow(0px 2px 10px rgba(245, 223, 77, 0.3));
            box-shadow: 0px 2px 10px rgba(245, 223, 77, 0.3);
            text-align: left;

            @media screen and (max-width: 1920px) {
                top: 53px;
            }

            @media screen and (max-width: $tableWidth) {
                left: 28px;
            }

            &.show {
                display: block;
            }

            &::before {
                content: '';
                position: absolute;
                border: 30px solid transparent;
                border-bottom: 20px solid rgb(0, 0, 0);
                display: block;
                width: 0;
                height: 0;
                top: -48px;
                left: 0;
                filter: drop-shadow(0px 2px 10px rgba(245, 223, 77, 0.3));
                // box-shadow: 0px 2px 10px rgba(245, 223, 77, 0.3);
                z-index: 9998;
                mix-blend-mode: overlay;
            }
        }
    }
}

.sidebar_block.info_block {
    margin-bottom: 27px;
    border-bottom: 0 !important;
    height: 100%;

    &::after {
        content: '';
        position: absolute;
        bottom: -16px;
        left: -2px;
        right: -2px;
        height: 16px;

        background-image: url("data:image/svg+xml,%3Csvg width='218' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h218v6.137h-15L182 16H97L76 6.137H0V0Z' fill='%23555038' fill-opacity='.2'/%3E%3Cpath d='M76 6.137 97 16h85l21-9.863h15V0h-2v4.137h-13.446l-21 9.863H97.446l-21-9.863H2V0H0v6.137h76Z' fill='%23555038'/%3E%3C/svg%3E");
    }
}

.earned_block {
    position: relative;
    // todo: refactor;
    border-top: 0 !important;
    border-bottom: 0 !important;
    padding-bottom: 0 !important;

    &::before {
        content: '';
        position: absolute;
        top: -12px;
        left: -2px;
        right: -2px;
        height: 12px;

        background-image: url("data:image/svg+xml,%3Csvg width='218' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M76.43 0H0v12h218V0h-15.437l-21.007 9.866H97.44L76.43 0Z' fill='%23555038' fill-opacity='.2'/%3E%3Cpath d='M76 2H2v10H0V0h76.446l21 9.863h84.108l21-9.863H218v12h-2V2h-13l-21 9.863H97L76 2Z' fill='%23555038'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -40px;
        left: -2px;
        right: -2px;
        height: 40px;
        background-image: url("data:image/svg+xml,%3Csvg width='218' height='40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 19.931V40h182l36-37V0H0v19.931Z' fill='%23555038' fill-opacity='.2'/%3E%3Cpath d='m182 40 36-37V0h-2v2.188L181.155 38H2V0H0v40h182Z' fill='%23555038'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
    }

    .block_information {
        margin-bottom: 16px;
    }

    .block_title {
        margin-bottom: 16px;
        padding-bottom: 6px;
        border-bottom: 1px solid #555038;
        position: relative;

        .info_icon {
            position: absolute;
            top: -9px;
            width: 20px;
            height: 20px;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .info_popup {
            display: none;
            position: absolute;
            top: 70px;
            left: 0;
            width: 95%;
            background: #000;
            z-index: 9999;
            box-sizing: border-box;
            padding: 12px 12px 12px 12px;
            font-size: 12px;
            line-height: 1.2;
            color: #f5df4d;
            line-height: 12px;
            box-shadow: 0px 2px 10px rgba(245, 223, 77, 0.3);
            text-align: left;
            font-weight: 400;
            letter-spacing: 0;

            @media screen and (max-width: 1920px) {
                top: 53px;
            }

            @media screen and (max-width: $tableWidth) {
                left: 28px;
            }

            &.show {
                display: block;
            }

            &::before {
                content: '';
                position: absolute;
                border: 30px solid transparent;
                border-bottom: 20px solid rgb(0, 0, 0);
                display: block;
                width: 0;
                height: 0;
                top: -48px;
                left: 0;
                filter: drop-shadow(0px 2px 10px rgba(245, 223, 77, 0.3));
                z-index: 9998;
                mix-blend-mode: overlay;
            }
        }
    }
    .information_item {
        padding: 0;
        border: none;

        .value {
            display: flex;
            align-items: center;
            font-size: px(12);
            letter-spacing: 0.1em;
            font-weight: 600;

            .icon {
                width: 20px;
                height: 20px;
                margin-bottom: 3px;
                margin-right: 5px;
                @media screen and (max-width: 1920px) {
                    width: 14px;
                    height: 14px;
                }

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .button_block {
        .dm-button {
            width: 100%;
        }
    }

    @media screen and (max-width: 1280px) {
        img {
            height: 98%;
        }
    }
}

.referal_block {
    @media screen and (max-width: 2660px) {
        top: -3%;
    }

    @media screen and (max-width: 1920px) {
        top: -1%;
    }

    @media screen and (max-width: 1366px) {
        top: -10%;
    }

    .block_title {
        margin-bottom: 0;
    }
    .block_subtitle {
        color: #aa9710;
        font-size: px(10px);
    }
    .block_link {
        position: relative;
        z-index: 69;
        margin: 19px 0;
        font-size: px(10);
        display: flex;
        align-items: center;

        button {
            cursor: pointer;
            background: none;
            border: none;
        }
        a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 62%;
            display: block;
            margin-right: 10px;
        }
    }
    .button_block {
        font-size: px(10);

        .btn {
            padding: 2px 25px;
            font-size: px(12);
        }
    }

    @media screen and (max-width: 1280px) {
        img {
            height: 90%;
        }
    }
}

.rewards-info_block {
    img {
        height: 100%;
    }
    .block_information {
        .information_item {
            &.indent {
                border-top: none;
                margin-top: 80px;

                @media screen and (max-width: 1440px) {
                    margin-top: 30px;
                }

                @media screen and (max-width: 1366px) {
                    margin-top: 20px;
                }
            }
        }
    }

    .button_block {
        display: flex;
        justify-content: center;
        align-self: center;
        margin-top: 36px;

        @media screen and (max-width: 1440px) {
            margin-top: 20px;
        }

        @media screen and (max-width: 1366px) {
            margin-top: 15px;
        }
    }
}
