.dm-filters-title {
    display: inline-flex;
    width: 121px;
    padding: 2px 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: $mainFontColor;
    background: #3f5761;
    clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
    text-transform: uppercase;
}
