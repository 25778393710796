.dm-button {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    max-width: 100%;
    padding: 7px 24px;
    border: $controlBorder solid $controlBorderColor;
    border-radius: $controlBorder;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    
    // height: var(--button-height);
    // font-family: var(--font-primary);
    // font-size: var(--button-font-size);
    // font-weight: var(--font-weight-text-regular);
    // line-height: var(--button-height);
    color: $accentColor;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.15s ease;
    -webkit-appearance: none;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 3px;
        height: 16px;
    }

    &::before {
        left: -2px;
    }

    &::after {
        right: -2px;
    }

    &:hover {
        color: #FFFAD8;
        background: radial-gradient(
            123.99% 50% at 49.79% 50%,
            rgba(245, 223, 77, 0.59) 0%,
            rgba(68, 61, 11, 0) 100%
        );
    }
}

.dm-button--primary {
    background: linear-gradient(
        354.69deg,
        rgba(245, 223, 77, 0.15) 1.85%,
        rgba(68, 61, 11, 0) 93.36%
    );
    border-color: $yellow;

    &::before,
    &::after {
        background-color: $yellow;
    }
}

.dm-button--secondary {
    background: rgba(85, 80, 56, 0.2);
    border-color: $darkYellow;

    &::before,
    &::after {
        background-color: $darkYellow;
    }
}

.dm-button--disabled {
    background: rgba(85, 80, 56, 0.2);
    border: 1px solid #555038;
    color: #555038;
    user-select: none;
    pointer-events: none;

    &::before,
    &::after {
        background-color: #555038;
    }
}

// Какие размеры сейчас используются: 44, 36, 32, 28 px

.dm-button--xl {
    height: 44px;

    &::before,
    &::after {
        height: 28px;
    }
}

.dm-button--l {
    height: 36px;

    &::before,
    &::after {
        height: 20px;
    }
}

.dm-button--md {
    height: 32px;
}

.dm-button--sm {
    height: 28px;
    padding: 5px;
    padding-bottom: 5px;

    &::before,
    &::after {
        height: 12px;
    }
}