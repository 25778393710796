.transaction_block {
    margin-top: 20px;
    width: 100%;
    color: $faqFontColor;
    font-size: px(12);
    line-height: 14px;
    height: 100%;
    overflow-y: auto;
}

.transaction_table {
    margin-left: 0;
    width: 100%;
    color: $primaryTextColor;

    @media screen and (max-width: $tableWidth) {
        margin-left: 16px;
    }

    th {
        white-space: nowrap;
        padding: 12px 0 12px 20px;
        cursor: pointer;
        font-size: px(16);
        font-weight: 600;
        position: sticky;
        top: 0;
        background: rgba(97, 157, 183, 0.2);
        backdrop-filter: blur(15px);

        .arrow_icon {
            width: 9px;
            height: 14px;
            margin-left: 8px;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .table_id {
        padding: 0;
        width: 20px;

        @media screen and (max-width: $tableWidth) {
            width: 10px;
        }
    }

    @media screen and (max-width: $tableWidth) {
        white-space: nowrap;
    }

    &__header {
        box-sizing: border-box;
        font-size: px(18);

        @media screen and (max-width: $tableWidth) {
            font-weight: 600;
            font-size: 16px;
        }
    }

    &__line {
        font-size: px(16);
        td {
            padding: 15px 0 4px 19px;
            line-height: 21px;
            border-bottom: 1px solid #3f5761;

            @media screen and (max-width: $tableWidth) {
                padding-right: 132px;
            }

            &.table_id {
                padding-right: 20px;
            }
            &.bloks-history {
                color: $yellow;
                cursor: pointer;
            }
        }

        @media screen and (max-width: $tableWidth) {
            margin-left: 16px;
            font-weight: 400;
            font-size: 16px;
        }
    }
}

.transaction__title {
    position: relative;
    display: block;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.095em;
    color: $primaryTextColor;
    text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
    margin-left: 0;
    margin-top: 50px;
    margin-bottom: 5px;

    @media screen and (max-width: $tableWidth) {
        display: block;
        margin-top: 10px;
        margin-bottom: 8px;
        margin-left: 16px;
    }
}

.transaction__title-info {
    display: none;
    position: absolute;
    top: 125%;
    width: 200px;
    background: #000;
    z-index: 9999;
    box-sizing: border-box;
    padding: 12px 12px;
    font-size: 14px;
    font-weight: 400;
    color: $primaryTextColor;
    line-height: 16px;
    box-shadow: 0px 2px 10px rgba(245, 223, 77, 0.3);

    @media screen and (max-width: $tableWidth) {
        left: 28px;
        top: 42px;
    }

    &::before {
        content: '';
        position: absolute;
        border: 8px solid transparent;
        border-bottom: 8px solid rgb(0, 0, 0);
        display: block;
        width: 0;
        height: 0;
        top: -14px;
        right: 6px;
        filter: drop-shadow(0px 2px 10px rgba(245, 223, 77, 0.3));
        z-index: 9998;
        mix-blend-mode: overlay;
    }
}

.transaction__title-tooltip {
    width: 14px;
    display: inline-block;
    margin-left: 4px;
    transform: translateY(-4px);

    & svg {
        width: 100%;
        height: auto;
    }
}

.transaction__title-tooltip:hover + .transaction__title-info {
    display: block;
}
