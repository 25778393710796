// Fonts
$mainFont                       : 'Mazzard', Helvetica, Arial, sans-serif;
$mainFontSize                   : 18px;
$mainFontWeight                 : 400;
$mainFontHeight                 : 1.4;

// Window Size

$largeDesktopWidth              : 1980px;
$desktopWidth                   : 1280px;
$smDesktopWidth                 : 980px;
$tableWidth                     : 1024px;
$phoneWidth                     : 480px;
$iphonePlusWidth                : 414px;
$smPhoneWidth                   : 360px;

// Colors

$mainFontColor                  : #F5DF4D;
$linkColor                      : #AA9710;
$hoverLinkColor                 : #F5DF4D;

$primaryTextColor               : #E4E4E4;

$mobileLinkColor                : #968E53;

$faqFontColor                   : #FFFAD8;

$alertLoginColor                : #FFFAD8;
$alertAuthColor                 : #00A3FF;
$alertWarningColor              : #FF0000;
$alertSuccessColor              : #05D132;

$scrollThumbColor               : #968e54;
$scrollTrackColor               : #242513;

$mainBackColor                  : rgba(85, 80, 56, .2);

// BorderColor

$borderButton                   : #565031;
$mainBorderColor                : #555038;


// v2
$yellow                         : #F5DF4D;
$darkYellow                     : #968E53;


$accentColor                    : $yellow;

// control
$controlRadius                  : 2px;
$controlBorder                  : 1px;
$controlBorderColor             : $darkYellow;

$panelBgColor                   : rgba(85, 80, 56, 0.2);