.dm-page-loader {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    color: $mainFontColor;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
}
