.login-btn {
    position: absolute;
    left: calc(50% - 84px);
    top: calc(50% + 157px);
    transform: translate(-50%, -50%);

    width: 300px;
    height: 300px;

    svg {
        width: 100%;
        height: 100%;
    }

    img {
        width: 100%;
    }

    @media screen and (max-width: 1920px) {
        top: calc(50% + 115px);
        width: 250px;
        height: 250px;
    }

    @media screen and (max-width: 1600px) {
        top: calc(50% + 7%);
    }

    @media screen and (max-width: $tableWidth) {
        left: 50%;
        top: calc(50% + 11%);
        width: 140px;
        height: 140px;
    }

    background: none;
    border-radius: 50%;
    border: 0;
    filter: drop-shadow(0px 0px 12px #fec411);
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 3px solid $accentColor;
        opacity: 0.5;
        animation: pulsate 2s ease infinite;
    }
}

@keyframes pulsate {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}
