@import './utils/vars';

.landing {
  color: white;
  font-family: $baiFont;
  font-weight: 400;

  &__background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
  }

  @media screen and (max-width: $phoneWidth) {
    &__background {
      left: 50%;
      width: 1010px;
      max-width: 1010px;
      height: 568px;
      transform: translate(-50%, 0);
    }
  }
}
