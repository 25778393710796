.dm-tab {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 175px;
    max-width: 100%;
    padding: 7px 24px;
    border: $controlBorder solid $darkYellow;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    background: rgba(85, 80, 56, 0.2);

    color: $accentColor;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.15s ease;
    -webkit-appearance: none;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 8.2px;
        height: 7.5px;
        transform: translateY(-50%);
        background-color: $darkYellow;
    }

    .dm-tab__icon {
        position: absolute;
        stroke: $darkYellow;
    }

    &:first-child {
        border-radius: $controlBorder 0 0 $controlBorder;

        &::after {
            left: -4px;
            clip-path: polygon(100% 50%, 0 100%, 0 0);
        }

        .dm-tab__icon {
            left: -1px;
        }
    }

    &:last-child {
        border-radius: 0 $controlBorder $controlBorder 0;

        &::after {
            right: -4px;
            clip-path: polygon(0 50%, 100% 0, 100% 100%);
        }

        .dm-tab__icon {
            right: -1px;
        }
    }
}

.dm-tab--active {
    border-color: $yellow;
    background: linear-gradient(
        354.69deg,
        rgba(245, 223, 77, 0.15) 1.85%,
        rgba(68, 61, 11, 0) 93.36%
    );

    &::after {
        width: 10.5px;
        height: 12px;
        background-color: $yellow;
    }

    &:first-child::after {
        left: -5px;
    }

    &:last-child::after {
        right: -5px;
    }

    .dm-tab__icon {
        stroke: $yellow;
    }
}
