.cards-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 175px));
    gap: 20px;

    // for prevent card decoration overflowing
    // see .card::before
    padding-left: 1px;

    @media screen and (max-width: $tableWidth) {
        grid-template-columns: repeat(auto-fit, minmax(142px, 1fr));
        gap: 15px;
    }
}

.card {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;

    background: rgba(77, 103, 114, 0.2);
    border: 1px solid #3f5761;
    color: #fff;

    &:hover {
        color: #fff;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        width: 3px;
        height: 3px;

        background: $accentColor;
    }

    &::before {
        left: -2px;
    }

    &::after {
        right: -2px;
    }

    &__badge {
        position: absolute;
        top: -1px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }

    &__preview {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 240px;
        transform: scale(1.04);
    }

    &__content {
        padding: 12px 8px 4px;
    }

    &__title {
        margin-bottom: 3px;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
    }

    &__bid {
        display: flex;
        justify-content: space-between;
        gap: 4px;
    }

    &__bid-count {
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: $primaryTextColor;

        &--winning {
            color: $accentColor;
            font-weight: 800;
        }

        &--losing {
            font-weight: 800;
            color: #f56b4d;
        }
    }

    &__price {
        font-size: 14px;
        line-height: 16px;

        &--losing {
            font-weight: 600;
            color: #f78e1d;
        }

        &--winning {
            font-weight: 600;
            color: $accentColor;
        }
    }

    &__time {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        text-align: center;
    }

    &__bottom {
        margin-top: auto;
        padding: 5px 8px 9px;
        background: rgba(63, 87, 97, 0.2);
    }

    &__button {
        width: 100%;
    }

    &--finished {
        .card__preview {
            opacity: 0.3;
        }

        .card__time {
            font-weight: 500;
        }
    }
}

.card-badge {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;

    color: #272727;

    &__text {
        padding: 0 10px;
        background-color: var(--background-color);
        clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 10px 100%);
    }

    &__figure {
        $skewDeg: 15deg;

        position: absolute;
        top: 5px;
        width: 100%;

        &::before,
        &::after {
            position: absolute;
            content: '';

            width: 3px;
            height: 9px;

            background-color: var(--background-color);
        }

        &::before {
            left: -2px;
            transform: rotate(-#{$skewDeg}) skew($skewDeg, $skewDeg);
        }

        &::after {
            right: -2px;
            transform: rotate($skewDeg) skew(-#{$skewDeg}, -#{$skewDeg});
        }
    }

    &::before {
        content: '';
        position: absolute;
    }

    &::before {
        top: 0;
        left: -25px;

        width: calc(100% + 50px);
        height: 1px;

        background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0%,
            var(--background-color) 35%,
            var(--background-color) 65%,
            rgba(0, 0, 0, 0) 100%
        );
    }

    &--winning {
        --background-color: #{$accentColor};
    }

    &--losing {
        --background-color: #f56b4d;
    }

    &--finished {
        --background-color: #fffad8;
    }

    &--cooldown {
        --background-color: #{$accentColor};
    }

    &--disabled {
        --background-color: #e4e4e4;
    }
}
