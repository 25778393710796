.discord-modal {
    text-align: center;

    .affiliate-modal__title {
        padding-top: 20px;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 52px auto 174px auto;

        span {
            padding-left: 8px;
        }
    }
}