.alert {
    width: 369px;
    position: relative;

    top: -94px;
    left: calc(50% - 85px);
    transform: translateX(-50%);

    @media screen and (max-width: $tableWidth) {
        top: 0px;
        width: 240px;
    }

    &__screen {
        width: 100%;
        height: auto;
    }

    &_content {
        position: absolute;
        top: 40%;
        width: 100%;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    font-size: px(16);

    &__caption {
        font-weight: 600;
    }

    &__icon {
        margin-bottom: 10px;
    }
}

.alert.login {
    position: absolute;
    top: 25%;
    color: $alertLoginColor;
    text-shadow: $alertLoginColor 1px 0 10px;

    @media screen and (max-width: 1700px) {
        top: 20%;
    }
}

.alert.success {
    position: absolute;
    top: 25%;
    color: $alertSuccessColor;
    text-shadow: 0px 0px 10px $alertSuccessColor;

    @media screen and (max-width: 1440px) {
        top: 15%;
    }
}

.alert.auth {
    position: absolute;
    transition: transform 0.2s ease-in;

    .alert_content {
        color: $alertLoginColor;
        text-shadow: 0px 0px 10px $alertAuthColor;
    }

    &:hover {
        transform: translateX(-50%) scale(1.1);
    }
}

.alert.warning {
    position: absolute;
    top: 20%;
    color: $alertWarningColor;
    text-shadow: 0px 0px 10px $alertWarningColor;

    @media screen and (max-width: 1440px) {
        top: 15%;
    }

    @media screen and (max-width: $tableWidth) {
        top: 12%;
    }
}
