.affiliate-modal-error {
    padding-top: 20px;
    text-align: center;

    .affiliate-modal__title {
        padding-top: 31px;
    }

    &__button {
        margin: 48px auto 24px auto;
    }
}