.email-modal {
    padding: 20px 20px 14px 20px;
    text-align: center;

    .affiliate-modal__desciption{
        font-size: 16px;
        padding-top: 4px;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        span {
            padding-left: 8px;
        }

        &:first-child {
            margin: 16px auto 36px auto;
        }

        &:last-child {
            margin: 36px auto 24px auto;
        }
    }

    &__hr {
        flex: 1;
        background: #545466;
        height: 1px;
    }

    &__hr-container {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-size: 16px;
            line-height: 20px;
            width: 48px;
            text-align: center;
        }
    }

    &__input {
        width: 100%;
        background: #000000;
        border: 1px solid #555038;
        box-sizing: border-box;
        border-radius: 2px;
        margin-top: 28px;

        font-size: 14px;
        line-height: 16px;
        color: #7E7E7E;
        padding: 12px;
    }

    &__code-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 14px;
        line-height: 16px;
        color: #F5DF4D;
        margin: 12px 0;

        &__action {
            text-decoration: underline;
            cursor: pointer;
        }

        &__resend, &__resend-info {
            font-size: 14px;
            line-height: 16px;
            color: #ACACAC;
            cursor: default;
            text-decoration: none;
        }

        &__resend-info {
            text-align: left;
            padding-bottom: 12px;
        }
    }
}