.referrals-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 21px;

  &__text {
    text-align: center;
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #F5DF4D;

    padding-top: 23px;
    max-width: 400px;
  }

  &__notice {
    font-size: 14px;
    line-height: 16px;
    color: #ACACAC;
  }

  &__instructions {
    margin-top: 38px;
    width: 100%;
    background: rgba(84, 84, 102, 0.12);
    padding: 20px;

    &__title {
      font-size: 14px;
      line-height: 16px;
      padding-bottom: 10px;
    }

    &__description {
      font-size: 14px;
      line-height: 16px;
      color: #ACACAC;
      padding-left: 20px;

      li {
        list-style-type: decimal;
      }
    }
  }
}