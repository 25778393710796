.dm-back-button {
    width: max-content;
    gap: 8px;
    padding: 0;
    font-size: 14px;
    background: none;
    border: none;
    color: $mainFontColor;
    display: flex;
    align-items: center;
}