.dm-rarity-marker {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;

    &--common {
        background: linear-gradient(180deg, #4a4a4a 0%, #c4c4c4 100%);
    }

    &--uncommon {
        background: linear-gradient(180deg, #1b4d0d 0%, #94ca02 100%);
    }

    &--rare {
        background: linear-gradient(180deg, #0c5493 0%, #138ede 100%);
    }

    &--epic {
        background: linear-gradient(180deg, #530f8a 0%, #b56bc6 100%);
    }

    &--legendary {
        background: linear-gradient(180deg, #754c14 0%, #ccb436 100%);
    }
}