@import '../../utils/vars';

$mobile-bg-padding: 8px;

.deepmine {
    padding-top: 35vw;
    max-width: 1010px;
    margin: 0 auto;

  &__title {
    font-family: $orbitronFont;
    font-weight: 900;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 34px;
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media screen and (max-width: $phoneWidth) {
    padding-top: 140px;

    &__title {
      font-size: 20px;
      line-height: 36px;
    }
  }
}

.deepmine-text {
  max-width: 335px;
  padding: 34px 11px;

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    padding-bottom: 8px;
  }

  @media screen and (min-width: 1010px) {
    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
      .deepmine-text__title {
        min-height: 56px;
      }
    }
  }

  @media screen and (max-width: 1010px) {
    &:nth-child(1), &:nth-child(2) {
      .deepmine-text__title {
        min-height: 56px;
      }
    }
  }

  @media screen and (max-width: 675px) {
    padding: 16px;

    .deepmine-text__title {
      min-height: auto !important;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ACACAC;
  }
}