.nft_page,
.information_page {
    @media screen and (max-width: $tableWidth) {
        background: rgba(77, 103, 114, 0.2);
        border: 0.5px solid #3f5761;
        box-sizing: border-box;
        backdrop-filter: blur(6px);
        height: 100%;
        padding: 17px;
    }
}

.nft_page.auth {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media screen and (max-width: $tableWidth) {
        padding: 0;
        border: none;
    }
}

.nft_page__content {
    height: 100%;

    @media screen and (max-width: $tableWidth) {
        height: calc(100% - 56px);
    }
}
