@import './Utils/fonts';
@import './Utils/vars';
@import './Utils/mixins';
@import './Utils/reset';

iframe {
    display: none !important;
}

html {
    line-height: $mainFontHeight;
    color: $primaryTextColor;
    font-family: $mainFont;
    background-color: #000;
}

html,
body,
#root,
.app-wrapper {
    height: 100%;
    @media screen and (max-width: $tableWidth) {
        height: 100%;
        min-height: 100%;
    }
}

a {
    color: $linkColor;
    transition: color 0.3s ease-in;

    &:hover {
        color: $hoverLinkColor;
    }
}

button {
    cursor: pointer;
}

.hide-mobile {
    @media screen and (max-width: 1024px) {
        display: none !important;
    }
}

.hide-desktop {
    @media screen and (min-width: 1024px) {
        display: none !important;
    }
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar:horizontal {
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $scrollTrackColor;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $scrollThumbColor;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #e1c54e;
}

.marked_text {
    color: $mainFontColor;
    font-weight: 600;
    font-size: px(13);

    @media screen and (max-width: $tableWidth) {
        font-size: px(18);
    }

    @media screen and (max-width: $smPhoneWidth) {
        font-size: px(18);
    }
}

input[type='text'],
input[type='number'],
textarea {
    font-size: 16px;
}

.text--bold {
    font-weight: bold;
}

.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

// v2
@import './v2/button';
@import './v2/Tabs';
@import './v2/pageTitle';
@import './v2/sidebar';
@import './v2/radio';
@import './v2/radioGroup';
@import './v2/Divider';
@import './v2/RarityMarker';
@import './v2/auctionCard';
@import './v2/BackButton';
@import './v2/Input';
@import './v2/FiltersTitle';
@import './v2/Select';
@import './v2/Switch';
@import './v2/Notice';
@import './v2/Link';
@import './v2/EmptyScreen';
@import './v2/Loader';

// Pages
@import './Containers/App';
@import './Containers/MainPage';
@import './Containers/Information';
@import './Containers/StatsPage';
@import './Containers/NftPage';
@import './Containers/AuctionPage';
@import './Containers/StorePage';
@import './Containers/DropPage';

// Components
@import './Components/header';
@import './Components/menu';
@import './Components/alert';
@import './Components/loginBtn';
@import './Components/caption';
@import './Components/btnBlock';
@import './Components/information';
@import './Components/modal';
@import './Components/showBtn';
@import './Components/sidebar';
@import './Components/statusBar';
@import './Components/cardBlock';
@import './Components/statsInformation';
@import './Components/transactionTable';
@import './Components/common';
@import './Components/stakingModal';
@import './Components/rewardsModal';
@import './Components/Notifications';
@import './Components/Pagination';
@import './Components/connectionLoader';
@import './Components/SidebarRarityMarkers';
@import './Components/mysteryBox';

// v2
@import './v2/button.scss';
@import './v2/Tabs.scss';
