$icon-margin-right: 21px;
$icon-margin-right--wide: 48px;
$icon_width: 88px;

.affiliate-level-icon {
  position: relative;
  width: $icon_width;
  margin-right: $icon-margin-right;
  cursor: pointer;

  &--locked {
    .affiliate-level-icon__image {
      background: #393947;
      border: 2px solid #646469;
    }
  }

  &--done {
    .affiliate-level-icon__image {
      background: #555038;
      border: 2px solid #F5DF4D;
    }
  }

  &__badge {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5DF4D;
    border-radius: 20px;
    z-index: 2;
  }

  &__image {
    box-sizing: border-box;
    position: relative;
    background: rgba(59, 59, 72, 0.48);
    display: flex;
    align-items: center;
    justify-content: center;
    width: $icon_width;
    height: $icon_width;

    img {
      width: 68px;
      height: 82px;
    }
  }

  &__title {
    padding-top: 8px;
    font-size: 16px;
    line-height: 20px;
    color: #7E7E7E;
    text-align: center;

    &--highlighted {
      color: #FFFFFF;
    }
  }

  &__line {
    position: absolute;
    background: rgba(59, 59, 72, 0.48);
    width: calc(#{$icon-margin-right} + 2px);
    margin-left: -2px;
    height: 4px;
    left: $icon_width;
    top: calc(#{$icon_width} / 2);
    transform: translateY(-50%);

    &--semi {
      background: linear-gradient(90deg, #F5DF4D 0%, #F5DF4D 50%, rgba(59, 59, 72, 0.48) 50%, rgba(59, 59, 72, 0.48) 100%);
    }

    &--full {
      background: #F5DF4D;
    }
  }

  &--wide {
    margin-right: $icon-margin-right--wide;

    .affiliate-level-icon__line {
      width: $icon-margin-right--wide;
    }
  }

  @media screen and (max-width: 600px) {
    margin: 16px;
  }
}