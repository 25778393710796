$affiliate-modal-bg: #272727;
$affiliate-modal-vertical-margin: 40px;

.affiliate-modal {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.48);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease-in;

  &--active {
    opacity: 1;
    pointer-events: all;
    backdrop-filter: blur(5px);

    .affiliate-modal__container {
      transform: scale(1);
    }
  }

  &__container {
    &::before,
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: $affiliate-modal-vertical-margin;
      background: $affiliate-modal-bg;
    }

    &::before {
      top: 0;
      clip-path: polygon(0 100%, 10% 0, 30% 0, 40% 40%, 60% 40%, 70% 0, 90% 0, 100% 100%);
    }

    &::after {
      bottom: 0;
      clip-path: polygon(0 0, 10% 100%, 30% 100%, 40% 59%, 60% 60%, 70% 100%, 90% 100%, 100% 0);
    }

    width: 436px;
    min-height: 1px;
    position: relative;
    transform: scale(0.5);
    transition: all 0.4s;
    padding: #{$affiliate-modal-vertical-margin - 0.5px} 0;
  }

  &__content {
    background: $affiliate-modal-bg;
  }

  &__close {
    position: absolute;
    right: 40px;
    top: 15px;
    cursor: pointer;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    max-width: 396px;
    margin: 0 auto;
  }

  &__description {
    font-size: 16px;
    line-height: 20px;
    color: #ACACAC;
    padding-top: 8px;
    max-width: 396px;
    margin: 0 auto;
  }
}