@import '../../utils/vars';

.landing-footer {
  display: flex;
  justify-content: center;

  padding-bottom: 65px;

  @media screen and (max-width: $phoneWidth) {
    padding-bottom: 35px;
  }
}