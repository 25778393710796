.confirm-title {
    padding-top: 30px;
    font-weight: 800;
    font-size: 32px;
    text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
    padding-bottom: 16px;
    width: 100%;
}

.confirm-error {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #d4401f;
    text-shadow: none;
    margin-bottom: 12px;
    padding: 0 65px;
}

.confirm-total {
    margin-bottom: 28px;
}

.confirm-description {
    margin-bottom: 12px;
}

.confirm-text-base {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
}

.confirm-submit {
    margin-bottom: 24px;
    min-width: 221px;
}

.confirm-wrapper {
    text-align: center;
    padding: 0 12px;
}
