.header-banner {
    color: #272727;
    position: relative;
    width: 235px;
    height: 60px;
    clip-path: polygon(100% 35%, 84% 100%, 0 100%, 0 0, 100% 0);
    background-color: #f5df4d;
    padding: 2px 12px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1024px) {
        clip-path: none;
        width: 100%;
        border: 2px solid #968E53;
    }

    &:hover {
        color: #272727;
    }

    &.desktop {
        display: block;
        flex-direction: column;

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }

    &.mobile {
        display: none;

        @media screen and (max-width: 1024px) {
            display: flex;
            flex-direction: row;
            column-gap: 40px;
        }

        &.starts-in > p {
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 20px;

        @media screen and (max-width: 1024px) {
            font-weight: bold;
        }
    }
    
    &__title {
        font-size: 32px;
        line-height: 36px;
        font-weight: bold;

        @media screen and (max-width: 1024px) {
            font-size: 24px;
            line-height: 28px;
        }
    }
}
