.menu_block {
    position: relative;
    width: 160px;
    height: calc(100% + 30px);

    padding-top: 30px;

    transition: all 0.4s ease-in;
    transform: translateX(-100%);
    opacity: 0;
    display: none;
    margin-top: -30px;
    &::after {
        content: '';
        position: absolute;
        bottom: 38px;
        left: 0;
        right: 0;
        height: calc(100% - 559px);
        background: rgba(85, 80, 56, 0.2);
        border: 2px solid #555038;
        border-top: none;
        border-bottom: 0;
    }

    &.show {
        transform: translateX(0);
        opacity: 1;
        display: block;
    }

    @media screen and (max-width: $tableWidth) {
        display: none;

        &.show {
            display: none;
        }
    }
}

.menu_block-top-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 160px;
    height: 521px;
    background: url('../../img/bg/menu-bg.svg');
}

.menu_block-bottom-bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 160px;
    height: 38px;
    background: url("data:image/svg+xml,%3Csvg width='160' height='38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M36 38h124V0H0v2l36 36Z' fill='%23555038' fill-opacity='.2'/%3E%3Cpath d='M36.828 36H158V0h2v38H36L0 2V0h2v1l.828 1 34 34Z' fill='%23555038'/%3E%3C/svg%3E")
        0 100% / cover no-repeat;
}

.nav_container {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 69;
    color: $mainFontColor;
    font-size: px(12);
    font-weight: 400;
    line-height: 14px;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 40px;

    @media screen and (max-width: 1920px) {
        margin-top: 10px;
    }
}

.menu_container {
    height: 100%;
}

.menu-list {
    width: 100%;
    padding: 0 2px;

    &__items {
        position: relative;
        margin-bottom: 20px;

        &__img {
            fill: currentColor;
        }
    }

    .background_container {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        bottom: -4px;

        background-image: url('../../img/menu/glow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: 0;
        visibility: hidden;

        transition: all 0.3s ease-in;
    }

    .img_container {
        width: 24px;
        height: 24px;

        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
    }

    &__items__link {
        display: flex;
        align-items: center;
        color: $primaryTextColor;
        font-size: 14px;
        padding: 4px 0 4px 17px;
        transform: translate(0);

        &.active_link:not(.notActive) {
            color: $mainFontColor;

            .background_container {
                opacity: 1;
                visibility: visible;
            }
        }

        &:hover {
            .background_container {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .badge {
        margin-left: 9px;
        padding: 1px 6px;
        font-size: 10px;
        line-height: 12px;
        font-weight: 800;
        border-radius: 12px;

        &--new {
            color: #555038;
            background: #f5df4d;
        }

        &--hot {
            color: #fff;
            background: #ff0000;
        }
    }
}

// Mobile menu
.menu_block__mobile {
    display: none;
    z-index: 69;
    height: 70px;
    box-sizing: border-box;

    padding: 10px 0;
    position: relative;
    bottom: unset;
    left: unset;

    @include blur-block;

    @media screen and (max-width: $tableWidth) {
        display: block;
    }

    .mobile_menu {
        &__list {
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            font-size: px(14);
        }

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $primaryTextColor;

            svg {
                width: 25px;
                height: 25px;
                margin-bottom: 5px;

                fill: currentColor;
            }

            &.active_link {
                filter: drop-shadow(0px 0px 4px rgba(245, 223, 77, 0.5));
                color: $mainFontColor;
            }
        }
    }
}

.menu-block-more {
    z-index: 10000;
    top: 76px;
    left: 0;
    position: fixed;
    padding: 20px 16px;
    width: 100%;
    display: block;
    font-size: 14px;

    &-list {
        background: rgba(63, 87, 97, 0.2);
        padding: 8px 14px;

        &-item {
            padding: 12px 0;
            & + & {
                border-top: 1px solid rgba(245, 223, 77, 0.1);
                padding-top: 12px;
            }

            &-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $primaryTextColor;

                &__main {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    svg {
                        width: 25px;
                        height: 25px;
                        fill: currentColor;
                    }
                }

                &__arrow {
                    width: 10px;
                    height: 10px;
                    fill: currentColor;
                }
            }
        }
    }
}
