#notifications {
  .notifications__toast {
    box-sizing: border-box;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.6);
    font-family: $mainFont;
    font-weight: 500;

    .Toastify__toast-icon {
      height: 44px;
      width: 44px;
      margin-right: 25px;
      background-repeat: no-repeat;

      & > svg {
        display: none;
      }
    }
  }

  .Toastify__toast--error {
    background: linear-gradient(90deg, rgba(245, 107, 77, 0.1) 0%, rgba(245, 107, 77, 0) 86%), linear-gradient(0deg, #1A2327 0%, #1A2327 100%);
    color: #F56B4D;

    .Toastify__toast-icon {
      background: url('../../img/notification/error.svg');
    }
  }

  .Toastify__toast--success {
    background: linear-gradient(90deg, rgba(130, 214, 109, 0.1) 0%, rgba(130, 214, 109, 0) 86%), linear-gradient(0deg, #1A2327 0%, #1A2327 100%);
    color: #82D66D;

    .Toastify__toast-icon {
      background: url('../../img/notification/success.svg');
    }
  }

  .Toastify__toast--warning {
    background: linear-gradient(90deg, rgba(245, 223, 77, 0.1) 0%, rgba(245, 223, 77, 0) 86%), linear-gradient(0deg, #1A2327 0%, #1A2327 100%);
    color: #F5DF4D;

    .Toastify__toast-icon {
      background: url('../../img/notification/warning.svg');
    }
  }

  .notifications__body {
    padding: 14px 8px 14px 22px;

    & > div:last-child {
      padding-top: 7px;
    }
  }

  .Toastify__close-button {
    opacity: 1;
    color: #3F5761;
  }
}
