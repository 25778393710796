.btn_block {
    width: 100%;
    // height: 60px; //TODO::FIX
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    box-sizing: border-box;

    @media screen and (max-width: $tableWidth) {
        height: auto;
    }

    .back_btn {
        background: none;
        border: none;

        color: $mainFontColor;
        display: flex;
        align-items: center;
        font-weight: 400;

        font-size: px(14);

        @media screen and (max-width: $tableWidth) {
            font-size: 14px;
        }

        @media screen and (max-width: $smPhoneWidth) {
            font-size: 14px;
        }

        svg {
            width: 21px;
            height: 21px;

            @media screen and (max-width: 1920px) {
                width: 12px;
                height: 12px;
            }
        }

        &__img {
            margin-right: 5px;

            @media screen and (max-width: $tableWidth) {
                margin-bottom: 3px;
            }
        }
    }

    .information_btn {
        .dm-button {
            min-width: 156px;

            @media screen and (max-width: 1200px) {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }
}

.btn_block__scroll {
    .page_type {
        font-size: px(20);
        font-weight: 600;
        letter-spacing: 0.14em;
        text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);

        .mobile_page_btn {
            width: 100%;
            display: none;
            justify-content: center;
            padding: 0 12px;

            @media screen and (max-width: 1024px) {
                display: flex;
            }

            .dm-tabs {
                display: flex;
            }

            .dm-tab {
                width: 148px;
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        .filters__container {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            .unselect {
                border: 1px solid #aa9710;
                box-sizing: border-box;
                font-size: px(16);
                margin-top: 8px;
                padding: 4px 12px;
            }

            .filter_block {
                display: flex;
                align-items: center;

                .selector_wrapper {
                    margin-right: 20px;
                    display: flex;
                    align-items: center;

                    @media screen and (max-width: 1200px) {
                        margin-right: 8px;
                    }

                    .selector_tag {
                        font-size: px(12);
                        font-weight: normal;
                        line-height: 14px;
                        letter-spacing: 0;
                        color: $primaryTextColor;
                        margin-right: 10px;
                        flex-shrink: 0;
                    }
                }

                .selector__toggle {
                    position: relative;
                    cursor: pointer;
                    font-size: px(12);
                    font-weight: normal;
                    line-height: 14px;
                    color: $primaryTextColor;
                    letter-spacing: 0;

                    @media screen and (max-width: $tableWidth) {
                        margin-right: 12px;
                    }

                    &:last-child {
                        margin-right: unset;
                    }

                    &.open {
                        .selected_value {
                            border-bottom-color: transparent;
                            &::after {
                                transform: translateY(-30%) rotate(180deg)
                                    scale(2.4);

                                @media screen and (max-width: 1920px) {
                                    transform: translateY(-50%) rotate(180deg)
                                        scale(1.4);
                                }
                            }
                            span {
                                opacity: 0;
                                visibility: hidden;
                            }
                        }
                    }
                    .selected_value {
                        font-size: px(12);
                        padding: 7px 8px 7px 16px;
                        border: 1px solid #968e53;
                        cursor: pointer;
                        width: 130px;
                        line-height: 16px;

                        @media screen and (max-width: 1200px) {
                            width: 100px;
                        }

                        &::after {
                            content: '';
                            background: url('../../img/icons/arrow.svg');
                            width: 9px;
                            height: 6px;
                            position: absolute;
                            top: 50%;
                            right: 20px;
                            transform: scale(1.6) translateY(-20%);
                            transform-origin: 50%;

                            @media screen and (max-width: 1920px) {
                                right: 10px;
                                transform: scale(1.4) translateY(-40%);
                            }
                        }
                    }
                    .selector__list {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        background-color: #000;

                        visibility: hidden;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 250px;
                        @media screen and (max-width: 1920px) {
                            width: 150px;
                        }
                        @media screen and (max-width: $tableWidth) {
                            width: 120px;
                        }
                        transition: all 0.1s ease-in-out;
                        padding: 28px 0 15px 0;
                        border: 1px solid #968e53;
                        z-index: 99;

                        .item {
                            padding: 8px 15px;
                            cursor: pointer;
                            font-size: px(12);
                        }

                        .item:hover {
                            color: $yellow;
                            background-color: rgba(245, 223, 77, 0.1);
                        }

                        &.show {
                            visibility: visible;
                        }
                    }
                }
            }
        }

        .btn {
            margin-right: 20px;
            @media screen and (max-width: $tableWidth) {
                font-size: 12px;
                letter-spacing: 0.14em;
                padding: 10px 20px;
            }

            @media screen and (max-width: $smPhoneWidth) {
                font-size: 12px;
                letter-spacing: 0.14em;
                padding: 10px 10px;
                margin-right: 0;
            }
        }
    }
}

.btn_block__is_auth {
    padding: 0 28px 0px 40px;
    margin-bottom: 40px;

    @media screen and (max-width: 1440px) {
        margin-bottom: 24px;
    }

    .btn_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #3f5761;
        padding: 35px 0 22px;

        @media screen and (max-width: $tableWidth) {
            flex-wrap: wrap;
            padding: 0;
            border-bottom: unset;
        }
    }

    .page_type {
        font-size: px(20);
        font-weight: 600;
        letter-spacing: 0.14em;

        @media screen and (max-width: $tableWidth) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;

            .filters__container {
                width: 100%;
                padding: 16px 14px;
                border-bottom: 1px solid #968e53;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        padding: unset;
        margin-bottom: 14px;
        margin-top: 0;
        width: 100%;
    }

    & .selects-cards {
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        padding: 0 12px 0;

        @media screen and (max-width: 1024px) {
            padding-top: 12px;
        }
    }
}
