.affiliate-link {


  &__input {
    width: 100%;
    display: flex;
    justify-content: space-between;

    background: rgba(59, 59, 72, 0.48);
    padding: 14px 12px;

    &--disabled {
      filter: blur(4px);
      user-select: none;
      pointer-events: none;
    }
  }

  &__copy {
    cursor: pointer;
  }

  &__invitation {
    padding-top: 72px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      font-weight: 800;
      font-size: 24px;
      line-height: 28px;
      text-transform: uppercase;
      color: #F5DF4D;
      max-width: 400px;
      padding-top: 20px;
    }

    &__description {
      font-size: 16px;
      line-height: 20px;
      color: #E4E4E4;
      max-width: 315px;
      padding-top: 8px;
    }
    &__button {
      margin-top: 24px;
      margin-bottom: 72px;
    }
  }
}


