.pagination_block {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination_container {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
        font-size: px(12);
        padding: 6px 23px;
        -webkit-appearance: none;
    }

    .arrow_btn {
        border: 1px solid $darkYellow ;
        background-color: unset;
        width: 30px;
        height: 32px;
        transition: all .2 ease-in;
        -webkit-appearance: none;

        &:hover {
            background: rgba(245, 223, 77, 0.1);
            border: 1px solid $darkYellow ;
            
        }

        svg {
            -webkit-appearance: none;
            width: 7px;
            height: 10px;
            min-width: 7px;
            min-height: 10px;
        }

        display: flex;
        align-items: center;
        justify-content: center;

        &.left_btn {
            margin-left: 20px;

            @media screen and (max-width: 1920px) {
                margin-left: 10px;
            }
        }

        &.rigth_btn {
            margin-right: 20px;

            @media screen and (max-width: 1920px) {
                margin-right: 10px;
            }
        }
    }

    .page_number {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $darkYellow ;
        height: 32px;
        font-size: 12px;
        line-height: 1;
        padding: 9px 10px;
        margin: 0 15px;
        cursor: default;
        color: #ACACAC;
    }
}