@import '../../utils/vars';

$join-margin: 116px;

.join {
  width: calc(100% - (#{$join-margin} * 2));
  box-sizing: border-box;
  margin: 190px $join-margin 116px $join-margin;
  padding: 75px 48px;
  display: flex;
  border: 2px solid #272727;
  background: radial-gradient(31.38% 92.25% at 88.13% 49.91%, #D6D6D6 0%, rgba(23, 23, 23, 0) 100%);

  &__title {
    font-family: $orbitronFont;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;
    max-width: 600px;
  }

  &__content {
    flex: 1;
  }

  &__button {
    margin: 38px auto 0 auto;
    width: 286px;
  }

  @media screen and (max-width: $phoneWidth) {
    background: radial-gradient(85.62% 45.67% at 50% 13.6%, #D6D6D6 0%, rgba(23, 23, 23, 0) 100%);
    margin: 92px 0 36px 0;
    padding: 50px 0;
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    border-left: none;
    border-right: none;


    &__image {
      width: 200px;
    }

    &__title {
      padding: 24px 16px 30px 26px;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
    }

    &__button {
      margin: 0 16px 0 16px;
      width: calc(100% - 32px);
    }
  }
}