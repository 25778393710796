$boxSize: 12px;

.dm-radio-button {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    input[type='radio'] {
        position: relative;
        box-sizing: border-box;
        width: $boxSize;
        height: $boxSize;
        margin: 0;
        background: #555038;
        border: 1px solid #f5df4d;
        border-radius: 50%;
        cursor: pointer;
        transition: border-color 0.15s, background-color 0.15s;
        -webkit-appearance: none;
        flex-shrink: 0;

        &::before {
            content: '';
            position: absolute;
            top: calc(50% - #{$boxSize} / 2);
            left: calc(50% - #{$boxSize} / 2);
            box-sizing: border-box;
            width: $boxSize;
            height: $boxSize;
            border-radius: 50%;
            opacity: 0;
            transition: background-color 0.15s, opacity 0.15s, transform 0.15s;
            transform: scale(0, 0);
            background: #fffad8;
        }

        &:checked {
            &::before {
                opacity: 1;
                transform: scale(0.4, 0.4);
            }
        }
    }

    &__label {
        margin-left: 6px;
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }
}
