@import '../../utils/vars';

.landing-main {
  width: 100%;
  padding-top: 50px;
  text-align: center;
  max-width: 760px;
  margin: 0 auto;

  &__title {
    font-family: $orbitronFont;
    font-weight: 900;
    font-size: 36px;
    line-height: 44px;
    text-transform: uppercase;
  }

  &__description {
    padding-top: 16px;
    font-size: 24px;
    line-height: 28px;
  }

  &__invited-by {
    display: flex;
    justify-content: center;
    padding-top: 28px;
    font-size: 20px;
    line-height: 28px;
    color: #ACACAC;

    span {
      text-decoration: underline;
    }
  }

  &__button {
    margin: 34px auto 0 auto;
  }

  &__what-is {
    padding-top: 26px;
    color: #F5DF4D;

    font-family: $orbitronFont;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    text-decoration: underline;
  }

  @media screen and (max-width: $phoneWidth) {
    padding: 46px 10px 12px 10px;

    &__title {
      font-size: 20px;
      line-height: 28px;
    }

    &__description {
      padding-top: 12px;
      font-size: 16px;
      line-height: 20px;
      color: $primaryTextColor;
    }

    &__invited-by {
      padding-top: 8px;
      font-size: 14px;
      line-height: 28px;
      color: $primaryTextColor;
    }

    &__button {
      width: calc(100% - 20px);
      margin: 44px 10px 0 10px;
      box-sizing: border-box;
    }

  }
}