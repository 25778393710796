.card-block {
    position: relative;
    font-size: px(12);
    line-height: 14px;
    height: calc(100% - 47px);
    margin-left: -10px;
    margin-top: -10px;

    &__left-bg,
    &__right-bg {
        position: absolute;
        background: #000;
        height: 460px;
        top: 0;
        z-index: -1;

        @media screen and (max-width: $tableWidth) {
            display: none;
        }
    }

    &__left-bg {
        left: 0;
        width: 199px;
        background: url('../../img/bg/main-content-inner-left-bg.svg') 0 0 /
            cover no-repeat;
    }

    &__right-bg {
        right: 0;
        width: 186px;
        background: url('../../img/bg/main-content-inner-right-bg.svg') 100% 0 /
            cover no-repeat;
    }

    &::after,
    &::before {
        content: '';
        position: absolute;
        background: rgba(97, 157, 183, 0.1);
        z-index: -1;

        @media screen and (max-width: $tableWidth) {
            content: none;
        }
    }

    &::before {
        top: 18px;
        left: 199px;
        right: 186px;
        height: 442px;
    }

    &::after {
        left: 10px;
        right: 0;
        bottom: 0;
        height: calc(100% - 460px);
    }

    @media screen and (max-width: $tableWidth) {
        height: 100%;
        background: rgba(97, 157, 183, 0.1);
        margin: 0;
    }

    .container {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 69;
    }

    .card__wrapper {
        height: calc(100% - 150px);
        overflow-y: auto;
        position: relative;
    }

    &__wrapper {
        height: max-content;
        gap: 32px 16px;
        grid-template-columns: repeat(auto-fill, minmax(140px, 191px));
        grid-auto-rows: auto;
        align-items: center;
        padding: 0 28px 0 40px;
        display: none;

        @media screen and (max-width: 1280px) {
            grid-template-columns: repeat(auto-fill, minmax(140px, 180px));
        }

        &.show {
            display: grid;
        }

        @media screen and (max-width: $tableWidth) {
            padding: 0 8px 25px 8px;
            grid-template-columns: repeat(auto-fill, 140px);
        }
    }
}

.sidebar_block {
    width: 100%;
    backdrop-filter: blur(5px);
}

.card-item {
    position: relative;
    background: url('../../img/card_inactive2.svg') no-repeat center;
    background-size: 100%;
    padding: 16px 20px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &.card-item__without {
        background: url('../../img/card-more.png') no-repeat center;
        background-size: 100%;

        .card-item__img {
            visibility: hidden;
            opacity: 0;
        }
    }

    @media screen and (max-width: $tableWidth) {
        background: none;
        padding: 0;
    }

    @media screen and (max-width: $tableWidth) {
        &.card-item__without {
            background: none;
            border: 1px solid rgba(170, 151, 16, 0.3);
        }
    }

    .show__more_wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(90% - 33px);
        height: calc(90% - 5px);
        transform: translate(-50%, -50%);
        font-weight: 800;
        font-size: px(24);
        color: #f5df4d;
        text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);

        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 2560px) {
            width: calc(90% - 27px);
        }
        @media screen and (max-width: 1920px) {
            height: calc(90% - 20px);
        }
    }

    &__img {
        position: relative;
        margin-bottom: 12px;
        margin-top: 12px;
        @media screen and (max-width: $tableWidth) {
            margin-bottom: 0;
            margin-top: 0;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 90%;
            min-height: 140px;
            height: auto;
            filter: drop-shadow(0px 4px 10px #343122);
            border: 0.5px solid rgba(170, 151, 16, 0.3);

            @media screen and (max-width: 2580px) {
                width: 93%;
            }
        }
    }

    .unstaked {
        position: absolute;
        top: 50%;
        left: 50%;

        opacity: 0;
        visibility: hidden;

        &.select {
            opacity: 1;
            visibility: visible;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(90% - 33px);
        height: calc(90% - 5px);
        @media screen and (max-width: 2560px) {
            width: calc(90% - 27px);
        }
        @media screen and (max-width: 1920px) {
            height: calc(90% - 20px);
        }
        @media screen and (max-width: 1440px) {
            height: calc(90% - 31px);
        }
        @media screen and (max-width: $tableWidth) {
            height: calc(100% - -1px);
            width: calc(100% - 12px);
        }
        transform: translate(-50%, -50%);
        box-shadow: 0px 4px 10px #343122;
        border: 0.5px solid rgba(170, 151, 16, 0.3);

        background: linear-gradient(
            0deg,
            rgba(41, 38, 23, 0.9),
            rgba(41, 38, 23, 0.9)
        );
        border: 0.5px solid rgba(170, 151, 16, 0.3);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px #343122;

        font-size: 14px;
        text-align: center;
        line-height: 1.2;

        .wrapper_stake {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            height: 100%;
            margin: 0 auto;
            padding: 4px 0;

            @media screen and (max-width: 1600px) {
                width: 94%;
            }

            @media screen and (max-width: 1440px) {
                width: 94%;
            }

            @media screen and (max-width: 1366px) {
                width: 96%;
                padding: 1vh 0;
            }

            @media screen and (max-width: 1280px) {
                width: 100%;
                padding: 1vh 0;
            }

            @media screen and (max-width: $tableWidth) {
                width: 90%;
            }

            @media screen and (max-width: $smPhoneWidth) {
                width: 98%;
            }

            .title {
                font-size: px(16);

                @media screen and(max-width: 1440px) {
                    font-size: px(16);
                }

                @media screen and(max-width: 1366px) {
                    font-size: px(14);
                }

                @media screen and (max-width: 1280px) {
                    padding-bottom: 2px;
                }

                @media screen and(max-width: $tableWidth) {
                    font-size: 14px;
                }
                @media screen and(max-width: $smPhoneWidth) {
                    font-size: 12px;
                }
                font-weight: 500;
                line-height: 122.2%;
                text-align: center;
                // color: #F5DF4D;
                text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
                border-bottom: 1px solid #f5df4d;
                padding-bottom: 10px;
            }

            .list {
                width: 100%;
                display: flex;
                flex-direction: column;
                font-size: px(12);

                line-height: 14px;
                padding: 6px 0;
                @media screen and(max-width: 1440px) {
                    font-size: px(12);
                    padding: 5px 0;
                }

                @media screen and(max-width: 1366px) {
                    font-size: px(11);
                    padding: 5px 0;
                }

                @media screen and(max-width: $tableWidth) {
                    font-size: 12px;
                }

                @media screen and(max-width: $smPhoneWidth) {
                    font-size: 10px;
                }
                .item {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 12px;

                    .text {
                        font-size: 10px;
                        font-weight: 400;
                        margin-bottom: 0.8vh;

                        @media screen and (max-width: 1920px) {
                            margin-bottom: 0.4vh;
                        }

                        @media screen and (max-width: 1440px) {
                            font-size: 10px;
                            margin-bottom: -1px;
                        }

                        @media screen and (max-width: 1366px) {
                            font-size: 10px;
                            margin-bottom: -1px;
                        }

                        @media screen and (max-width: 1280px) {
                            font-size: 9px;
                            margin-bottom: 0;
                        }

                        @media screen and (max-width: $tableWidth) {
                            font-size: 10px;
                            margin-bottom: 0px;
                        }

                        @media screen and (max-width: $smPhoneWidth) {
                            font-size: 10px;
                        }
                    }

                    .value {
                        font-size: 12px;
                        font-weight: 600;

                        @media screen and (max-width: 1366px) {
                            font-size: 10px;
                        }

                        @media screen and (max-width: 1280px) {
                            font-size: 9px;
                        }

                        @media screen and (max-width: $tableWidth) {
                            font-size: 12px;
                        }

                        @media screen and (max-width: $smPhoneWidth) {
                            font-size: 10px;
                        }
                    }

                    &:last-child {
                        margin-bottom: unset;
                    }
                }
            }
        }

        transition: all 0.3s ease-in;
    }

    .select_element {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(90% - 26px);
        height: calc(90% - 20px);
        transform: translate(-50%, -50%);
        box-shadow: 0px 4px 10px #343122;
        border: 0.5px solid rgba(170, 151, 16, 0.3);

        background: rgba(41, 38, 23, 0.8);
        border: 0.5px solid rgba(170, 151, 16, 0.3);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px #343122;

        font-size: 14px;

        @media screen and (max-width: 1440px) {
            font-size: px(14);
        }
        font-weight: 500;
        text-align: center;
        line-height: 1.2;
        text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);

        transition: opacity 0.3s ease-in;
    }

    &__info {
        color: #aa9710;
        font-size: px(12);
        transition: all 0.3s ease-in;
        padding-left: 14px;

        @media screen and (max-width: $tableWidth) {
            padding-left: 6%;
            font-size: px(15);
        }
    }

    .info-item {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        &__title {
            width: 70%;
        }
    }

    &-checkbox {
        cursor: pointer;
        position: absolute;
        display: none;
        background: $mainBorderColor;
        border: 1px solid $darkYellow;
        border-radius: 1px;
        width: 20px;
        height: 20px;
        left: 18px;
        top: 20px;
        z-index: 1;

        @media screen and (max-width: $tableWidth) {
            left: 0;
            top: 0;
        }

        &__mark {
            fill: $mainFontColor;
        }
    }
}

.card-item:hover {
    background: url('../../img/card_active2.svg') no-repeat center;
    background-size: 99%;

    .select_element {
        opacity: 1;
        visibility: visible;
    }

    @media screen and (max-width: $tableWidth) {
        background: none;

        .select_element {
            display: none;
        }
    }

    .card-item-checkbox {
        display: inline-block;
    }
}

.card-item.select {
    .card-item-checkbox {
        display: inline-block;
    }
}

.card-item.select:hover {
    .select_element {
        display: none;
    }
}

.card-item__without:hover {
    background-size: 100%;

    @media screen and (max-width: $tableWidth) {
        background: none;
    }
}

.card-item.select {
    background: url('../../img/card_active2.svg') no-repeat center;
    background-size: 99%;
    @media screen and (max-width: $tableWidth) {
        background: none;
    }
}

.alert-nft {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    text-align: center;

    &__text {
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 28px;
    }

    &__info {
        margin-top: 9px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }
}
