.main-screen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    position: relative;

    &__connect-wax {
        position: absolute;
        transform: translateX(-50%);

        left: calc(50% - 84px);
        top: calc(50% + 400px);

        @media screen and (max-width: 1920px) {
            top: calc(50% + 330px);
        }

        @media screen and (max-width: 1600px) {
            top: calc(50% + 200px);
        }

        @media screen and (max-width: $tableWidth) {
            left: 50%;
            top: calc(50% + 150px);
        }
    }

    & &__monitor-links {
        position: relative;
        height: 412px;
        width: auto;

        a img {
            height: 100%;
        }
    }

    .alert {
        @media screen and (max-width: $tableWidth) {
            left: 50%;
        }
    }

    &.shelter {
        @media screen and (max-width: $tableWidth) {
            width: 100%;
        }

        .navigation-button {
            position: absolute;
            top: 50%;

            display: flex;
            flex-direction: column;

            gap: 20px;

            &__button {
                width: 198px;
            }
        }

        @media screen and (max-width: $tableWidth) {
            .show-btn {
                width: 39vh;
                top: unset;
                bottom: 0;
                left: 17.2vw;
                transform: translate(0, 0);

                left: 50%;
                transform: translateX(-50.5%);
            }
        }

        @media screen and (max-width: $tableWidth) and (max-height: 1024px) {
            .show-btn {
                width: 39vh;
                top: unset;
                bottom: 0;
            }
        }

        @media screen and (max-width: $tableWidth) and (max-height: 950px) {
            .show-btn {
                width: 36.5vh;
                top: unset;
                bottom: 0;
            }
        }

        @media screen and (max-width: $tableWidth) and (max-height: 812px) {
            .show-btn {
                width: 37vh;
                top: unset;
                bottom: 0;
            }
        }

        @media screen and (max-width: $tableWidth) and (max-height: 736px) {
            .show-btn {
                width: 36.5vh;
                top: unset;
                bottom: 0;
            }
        }

        @media screen and (max-width: $tableWidth) and (max-height: 705px) {
            .show-btn {
                width: 36.5vh;
                top: unset;
                bottom: 0;
            }
        }

        @media screen and (max-width: $tableWidth) and (max-height: 553px) {
            .show-btn {
                width: 29.5vh;
                top: unset;
                bottom: 0;
            }
        }

        @media screen and (max-width: 375px) and (max-height: 560px) {
            .show-btn {
                width: 29.5vh;
                top: unset;
                bottom: 0;
            }
        }
    }
}
