.dm-notice {
    padding: 12px 16px;
    border-style: solid;
    border-width: 1px;
    backdrop-filter: blur(15px);

    &.default {
        border-color: rgba(63, 87, 97, 0.2);
        background: rgba(63, 87, 97, 0.2);
    }
}
