.connection-loader {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;

    padding: 15px;

    background: #1a2327;
    border: 1px solid rgba(63, 87, 97, 0.2);
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.6);

    @media screen and (min-width: 768px) {
        padding: 10px 24px 11px 31px;
    }

    &__img {
        width: 57px;
        margin-right: 12px;

        @media screen and (min-width: 768px) {
            margin-right: 32px;
        }
    }

    &__text {
        color: $accentColor;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;

        @media screen and (min-width: 768px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
}
