.drop-category {
    --bg-color: #acacac;
    --border-color: #acacac;
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;
    padding: 11px 11px 14px;
    background-color: #000;
    border: 2px solid var(--border-color);
    color: #272727;

    @media screen and (max-width: $tableWidth) {
        padding: 9px;
    }

    &--whitelist {
        --bg-color: #f5df4d;
        --border-color: #968e53;
    }

    &__top {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        margin-bottom: 10px;
        padding: 8px 15px;
        background-color: var(--bg-color);

        @media screen and (max-width: $tableWidth) {
            grid-template-columns: 1fr;
            padding: 8px 10px;
        }
    }

    &__title {
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
    }

    &__counter {
        font-size: 20px;
        line-height: 24px;
        font-weight: 800;
        text-align: center;

        @media screen and (max-width: $tableWidth) {
            text-align: left;
        }
    }

    &__info {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        text-align: right;

        @media screen and (max-width: $tableWidth) {
            display: none;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(140px, 180px));
        gap: 14px;
        height: 100%;

        padding-bottom: 12px;
        border-bottom: 2px solid var(--bg-color);

        @media screen and (max-width: $tableWidth) {
            gap: 12px;
        }

        &--wide {
            display: flex;
        }
    }
}

.drop-start {
    display: grid;
    place-items: center;
    align-content: center;
    height: 100%;
    font-weight: bold;
    color: $mainFontColor;

    &__title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 12px;
    }

    &__content {
        font-size: 48px;
        line-height: 56px;
        text-align: center;
    }
}
