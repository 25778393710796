* {
    box-sizing: border-box;
}

.app-wrapper {
    &__content {
        min-height: 800px;
        max-height: 100vh;
        position: relative;
        overflow: hidden;
        background-image: url('../../../assets/img/bunker_main.jpg');
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        transition: all 0.4s ease-in;

        @media screen and (min-height: 800px) {
            background-attachment: fixed;
        }

        @media screen and (max-width: 1024px) {
            min-height: 480px;
        }
        @media screen and (min-width: 3440px) {
            background-position: 0 45%;
        }

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
        }
    }

    &.not_auth,
    &.auth {
        .app-wrapper__content {
            background-image: url('../../../assets/img/room.jpg');

            @media screen and (min-width: 3440px) {
                background-position: center top;
            }
        }

        @media screen and (max-width: $tableWidth) {
            &.shelter {
                background-position: -150px;
            }
        }
    }

    &.success {
        .app-wrapper__content {
            background-image: url('../../img/bunker_success.jpg');
        }
    }

    &.error {
        .app-wrapper__content {
            background-image: url('../../img/bunker_error.jpg');
        }
    }

    &.shadow::before {
        .app-wrapper__content {
            display: block;
            z-index: 1000;
        }
    }

    &__container {
        height: 100%;
        padding: 8px;
        overflow: hidden;

        @media screen and (max-width: 1024px) {
            padding: 0;
        }

        @media screen and (max-width: $tableWidth) {
            overflow: hidden;
            width: 100%;
            height: 100vh;
            margin: 0;

            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    &__container.blur {
        backdrop-filter: blur(15px);
    }
}

.main-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 120px);
    z-index: 1000;

    @media screen and (max-width: $tableWidth) {
        height: calc(100% - 146px);
        display: flex;
        flex-direction: column;
    }

    &.menu_hide {
        height: 100vh;
    }
}

.main-wrapper.main-wrapper--with-banner {
    @media screen and (max-width: $tableWidth) {
        height: calc(100% - 200px);
    }
}

.app-wrapper.auth:not(.shelter):not(.error),
.app-wrapper.not_auth {
    .content--drop,
    .content--affiliate {
        .content__left-bg {
            background: url('../../img/bg/dark-left-bg.svg') 0 0 / cover
                no-repeat;
        }

        .content__right-bg {
            background: url('../../img/bg/dark-right-bg.svg') 100% 0 / cover
                no-repeat;
        }
    }

    .content__left-bg {
        width: 296px;
        height: 557px;
        background: url('../../img/bg/main-content-left-bg.svg') 0 0 / cover
            no-repeat;
    }

    .content__right-bg {
        width: 454px;
        height: 557px;
        background: url('../../img/bg/main-content-right-bg.svg') 100% 0 / cover
            no-repeat;
    }

    .content__container {
        &::before,
        &::after {
            content: '';
            position: absolute;
            background: rgba(63, 87, 97, 0.2);
            z-index: -1;

            @media screen and (max-width: $tableWidth) {
                content: none;
            }
        }

        &::before {
            top: 0;
            left: 296px;
            width: calc(100% - 750px);
            height: 100%;
            max-height: 557px;
            border-top: 2px solid #3f5761;
        }

        &::after {
            left: 12px;
            right: 0;
            bottom: 0;
            height: calc(100% - 557px);
            border: 2px solid #3f5761;
            border-top: 0;
        }
    }
}

.content {
    position: relative;
    width: calc(100% - 160px);
    margin-top: -65px;

    .content__left-bg,
    .content__right-bg {
        position: absolute;
        top: 0;
        z-index: -1;

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }

    .content__left-bg {
        left: 0;
    }

    .content__right-bg {
        right: 0;
    }

    &__container {
        position: relative;
        z-index: 69;
        width: 100%;
        height: 100%;
        padding: 32px;
        padding-right: 20px;

        @media screen and (max-width: 1024px) {
            background: none;

            &::before,
            &::after {
                content: none;
            }
        }

        &.not_auth {
            @media screen and (max-width: $tableWidth) {
                padding: 0;
            }
        }

        @media screen and (max-width: $tableWidth) {
            padding: 0;
        }
    }

    @media screen and (max-width: $tableWidth) {
        width: 100%;
        height: 100%;
        margin-top: 0;
    }

    &--drop,
    &--affiliate {
        .content__container {
            &::before {
                background: rgba(84, 84, 102, 0.12) !important;
                border-top: 2px solid #545466 !important;
            }
            &::after {
                background: rgba(84, 84, 102, 0.12) !important;
                border: 2px solid #545466 !important;
                border-top: none !important;
            }
        }
    }

    &--affiliate {
        overflow: hidden;

        .content__container {
            padding-bottom: 12px;
        }
    }
}

.app-wrapper:not(.shelter):not(.error),
.app-wrapper.not_auth {
    .main-wrapper--with-sidebar {
        .content--drop,
        .content--affiliate,
        .content--not-auth {
            .content__right-bg {
                background: url('../../img/bg/dark-right-small-bg.svg') 100% 0 /
                    cover no-repeat;
            }
        }

        .content__right-bg {
            width: 282px;
            height: 557px;
            background: url('../../img/bg/main-content-right-small-bg.svg') 100%
                0 / cover no-repeat;
        }

        .content__container {
            &::before {
                width: calc(100% - 578px);
                left: 296px;
                right: 282px;
                width: auto;
            }

            @media screen and (max-width: 1024px) {
                background: none;
            }
        }
    }
}

.main-wrapper--with-sidebar {
    .content {
        width: calc(100% - 387px);

        @media screen and (max-width: 1024px) {
            width: 100%;
            margin-top: 0;
        }
    }
}
