.affiliate-section-container {
  width: 100%;
  max-width: 680px;
  margin: 0 auto 24px auto;
  padding: 24px;
  background: rgba(84, 84, 102, 0.12);
  position: relative;

  &:first-child {
    margin-top: 60px;
  }

  &:last-child {
    margin-bottom: 60px;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #E4E4E4;
    padding-bottom: 24px;
  }
}