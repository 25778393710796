.store-product-page-description {
    background: rgba(63, 87, 97, 0.2);
    padding: 16px;

    &-title {
        font-size: px(16);
        font-weight: 700;
    }

    &-text {
        font-size: px(14);
        font-weight: 600;
        padding: 16px 0;
    }

    &-table img {
        width: 100%;
        height: auto;
        max-width: 550px;
    }

    &-divider {
        background: rgba(63, 87, 97, 0.2);
        height: 1px;
        width: 100%;
        margin: 4px 0 16px 0;
    }
}

.store-product-page-divider {
    background: rgba(63, 87, 97, 0.2);
    height: 1px;
    width: 100%;
    margin: 24px 0; 
}