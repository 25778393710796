.nft_page {
    &__mobile-buttons {
        display: none;
        padding: 12px;

        @media screen and (max-width: 1024px) {
            display: flex;
            justify-content: space-between;
        }

        & .dm-button {
            width: 97px;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}
