.sidebar-rarity-markers {
    font-size: 12px;
    line-height: 16px;
    display: grid;
    row-gap: 8px;
    grid-template-columns: 1fr auto auto;

    &__value {
        padding-left: 4px;
        justify-self: end;
    }
}