.modal {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
    pointer-events: none;
    transition: all 0.4s ease-in;

    &.active {
        opacity: 1;
        pointer-events: all;
        backdrop-filter: blur(5px);
    }

    &__content {
        &.congratulations {
            width: 400px;
            height: 250px;

            @media screen and (max-width: $tableWidth) {
                width: 288px;
                height: 209px;
            }

            .congratulations_modal {
                h2 {
                    margin-top: 68px;
                    font-size: px(18);

                    @media screen and (max-width: $tableWidth) {
                        margin-top: 50px;
                    }

                    @media screen and (max-width: 1920px) {
                        font-size: px(24);
                    }
                    font-weight: 600;
                    line-height: 122.2%;
                    text-align: center;
                    color: $primaryTextColor;
                    text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
                }

                .text {
                    font-weight: 300;
                    font-size: px(22);
                    @media screen and (max-width: 1920px) {
                        font-size: px(26);
                    }
                    color: $primaryTextColor;
                    line-height: 122.2%;
                    text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
                    margin-bottom: 32px;
                }

                .button_blocks {
                    .dm-button {
                        width: 150px;
                    }
                }
            }
        }

        &.DMP {
            width: 400px;
            height: 250px;

            @media screen and (max-width: $tableWidth) {
                width: 288px;
                height: 209px;
            }

            .DMP_modal {
                .text {
                    width: 300px;
                    font-size: 24px;
                    line-height: 28px;
                    margin-top: 62px;
                    text-align: center;
                    font-weight: 400;
                    @media screen and (max-width: 1920px) {
                        font-size: px(22);
                    }
                    color: $primaryTextColor;
                    text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
                    margin-bottom: 32px;

                    @media screen and (max-width: $tableWidth) {
                        margin-top: 50px;
                        font-size: 18px;
                        margin-bottom: 20px;
                    }
                }

                .button_blocks {
                    .dm-button {
                        width: 150px;
                    }
                }
            }
        }

        @media screen and (max-width: $tableWidth) {
            min-width: 320px;
        }

        position: relative;
        transform: scale(0.5);
        transition: all 0.4s;
        &.active {
            transform: scale(1);
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .content_block {
        max-width: 400px;
        z-index: 9999;
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .wax__logo {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .modal__icon {
                top: 0;
                left: calc(50% - 150px);
            }

            @media screen and (max-width: $tableWidth) {
                svg {
                    width: 245px;
                    height: 142px;
                }
            }

            span {
                text-align: center;
                font-size: px(24);
                margin-top: -128px;
                margin-bottom: 44px;
                text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);

                @media screen and (max-width: $tableWidth) {
                    font-size: 18px;
                    margin-top: -84px;
                }
            }
        }

        .link__create {
            margin-top: -24px;
            color: #ACACAC;
            text-decoration: none;
            font-size: 12px;
            transition: all 0.2s ease-in;

            @media screen and (max-width: $tableWidth) {
                margin-top: -36px;
            }

            &:hover {
                text-decoration: underline;
            }

            &.indent {
                margin-top: 16px;
                margin-bottom: 50px;
                @media screen and (max-width: $tableWidth) {
                    margin-top: 14px;
                }
            }
        }

        .wax__form {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0 24px;
            flex-wrap: wrap;
            margin-top: 32px;

            @media screen and (max-width: $tableWidth) {
                margin-top: 10px;
            }

            .form__input {
                width: 100%;
                border-bottom: 0.5px solid #4e4506;
                color: #968e53;
                padding: 13px;
                font-size: px(16);
                background: none;

                @media screen and (max-width: $tableWidth) {
                    font-size: 16px;
                }

                &::placeholder {
                    color: #ACACAC;
                    font-size: 16px;
                    line-height: 20px;
                }

                &:first-child {
                    border-top: 0.5px solid #4e4506;
                }
            }
            
            .wax-login-btn {
                margin-top: 32px;
            }
        }
    }

    .unstake_modal {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 40px;

        @media screen and (max-width: $tableWidth) {
            padding: 0 20px;
        }

        .content_block {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            max-width: 325px;
            height: 100%;
            padding: 80px 0;
            text-align: center;
            color: $primaryTextColor;

            @media screen and (max-width: 1920px) {
                padding: 64px 0;
            }

            @media screen and (max-width: $tableWidth) {
                padding: 37px 0;
            }

            .title_block {
                font-weight: 800;
                font-size: px(32);
                line-height: 122.2%;
                text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
                padding-bottom: 24px;
                border-bottom: 0.5px solid $mainBorderColor;
                width: 100%;
            }

            .title {
                margin-top: 20px;
                font-size: 24px;
                line-height: 32px;
                font-weight: 500;
                text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
            }

            .name {
                margin-top: 16px;
                font-size: px(16);
                font-weight: 500;
                text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
            }

            .value {
                line-height: 28px;
                margin-bottom: 20px;
                font-size: px(24);
                font-weight: 500;
                text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
            }

            .button_blocks {
                width: 100%;
                display: flex;
                gap: 12px;
                justify-content: space-between;
                align-items: center;
                border-top: 0.5px solid $mainBorderColor;
                padding: 28px 8px 0 8px;

                .dm-button {
                    width: 100%;
                    max-width: 140px;
                }
            }
        }
    }
}

.claim_modal {
    padding: 0 40px;
    width: 100%;
    height: 100%;

    @media screen and (max-width: $tableWidth) {
        padding: 0 28px;
    }

    .content_block {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 80px 0;
        text-align: center;
        color: $primaryTextColor;
        text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);

        @media screen and (max-width: 1920px) {
            padding: 64px 0;
        }

        @media screen and (max-width: $tableWidth) {
            padding: 37px 0;
        }

        .title_block {
            font-weight: 800;
            font-size: px(32);
            line-height: 122.2%;
            text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
            padding-bottom: 24px;
            border-bottom: 0.5px solid $mainBorderColor;
            width: 100%;
        }

        .content_wrapper-block {
            margin: 20px 0;
            .name {
                font-size: px(24);
                font-weight: 500;
                text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
                margin-bottom: 1.4vh;
            }

            .value {
                display: flex;
                align-items: center;
                font-size: px(24);
                font-weight: 600;
                letter-spacing: 0.1em;
                color: $mainFontColor;

                svg {
                    margin-right: 6px;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .button_blocks {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 12px;
            border-top: 0.5px solid $mainBorderColor;
            padding: 28px 8px 0 8px;

            .dm-button {
                width: 150px;
            }
        }
    }

    .claim {
        width: 100%;

        &__title {
            font-size: px(32);
            font-weight: 800;

            @media screen and (max-width: $tableWidth) {
                font-size: px(28);
                font-weight: 600;
            }
        }

        &__text {
            font-size: px(16);
        }
    }

    .information {
        width: 100%;
        margin-top: 64px;
        margin-bottom: 55px;
        @media screen and (max-width: $tableWidth) {
            margin-top: 32px;
            margin-bottom: 33px;
        }
        font-size: px(16);
        .line {
            display: flex;
            margin-bottom: 5px;

            @media screen and (max-width: $tableWidth) {
                justify-content: space-between;
            }

            .name,
            .value {
                width: 50%;
            }

            @media screen and (max-width: $tableWidth) {
                .value {
                    width: 30%;
                }
            }
        }
    }

    .button_blocks {
        .btn {
            font-weight: 600;
            font-size: px(12);
            padding: 17px 50px;
            @media screen and (max-width: $tableWidth) {
                padding: 12px 32px;
            }
            border-color: $mainFontColor;
        }
    }
}

.staking_modal {
    padding: 0 40px;
    font-size: px(16);
    width: 100%;
    height: 100%;

    @media screen and (max-width: $tableWidth) {
        padding: 0 20px;
    }

    .content_block {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 80px 0;
        text-align: center;
        color: $primaryTextColor;
        text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);

        @media screen and (max-width: 1920px) {
            padding: 64px 0;
        }

        @media screen and (max-width: $tableWidth) {
            padding: 37px 0;
        }

        .title_block {
            font-weight: 800;
            font-size: px(32);
            line-height: 122.2%;
            text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
            padding-bottom: 24px;
            border-bottom: 0.5px solid $mainBorderColor;
            width: 100%;
        }

        .staking_list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 24px;

            .item {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title {
                    width: 50%;
                    text-align: left;
                }

                .value {
                    svg {
                        width: 16px;
                        height: 16px;
                        margin-bottom: 4px;
                        margin-right: 8px;
                    }
                }
            }

            .item + .item {
                margin-top: 16px;
            }
        }

        .button_blocks {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 12px;
            border-top: 0.5px solid $mainBorderColor;
            padding: 28px 8px 0 8px;

            .dm-button {
                width: 100%;
                max-width: 140px;
            }
        }
    }

    .staking {
        width: 100%;
        &__title {
            font-size: px(32);
            font-weight: 800;
            line-height: 122.2%;
            margin-bottom: 30px;
            text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
            @media screen and (max-width: $tableWidth) {
                font-size: px(28);
                font-weight: 600;
            }
        }
    }

    .staking_list {
        width: 100%;
        display: flex;
        align-items: center;

        &:last-child {
            margin-bottom: 140px;
            @media screen and (max-width: 1920px) {
                margin-bottom: 90px;
            }
            @media screen and (max-width: $tableWidth) {
                margin-bottom: 30px;
            }
        }
    }

    .staking_content {
        width: 100%;
    }

    .time_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        margin-top: 20px;

        .title {
            font-size: px(16);
            color: $primaryTextColor;
            text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
        }

        @media screen and (max-width: $tableWidth) {
            margin-bottom: 12px;
        }

        align-items: center;

        &:last-child {
            margin-bottom: 81px;
        }

        .time__wrapper {
            height: 40px;
            width: 150px;
            @media screen and (max-width: $tableWidth) {
                width: 120px;
            }
            border: 1px solid #968e53;
            box-sizing: border-box;
            color: $mainFontColor;
        }
    }

    .time__toggle {
        position: relative;
        cursor: pointer;
        &.open {
            .selected_value {
                border-bottom-color: transparent;
                &::after {
                    transform: translateY(-30%) rotate(180deg) scale(1.2);
                }
                span {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
        .selected_value {
            font-size: 14px;
            padding: 4px 8px;
            border: 1px solid #968e53;
            cursor: pointer;
            width: 145px;
            text-align: left;
            line-height: 16px;

            &::after {
                content: url('../../img/icons/arrow.svg');
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-60%) scale(1.2);

                z-index: 100;
            }
        }
        .time__list {
            font-size: 14px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: #000;

            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 145px;
            text-align: left;
            transition: all 0.2s ease-in;
            padding: 30px 0 8px;
            border: 1px solid #968e53;
            z-index: 99;

            .time {
                transition: all 0.2s ease-in;
                padding: 8px;
                cursor: pointer;
            }

            .time:hover {
                background-color: #968e53;
            }

            &.show {
                visibility: visible;
            }
        }
    }

    .button_blocks {
        width: 100%;
        display: flex;
        align-items: center;

        .btn {
            font-weight: 600;
            font-size: px(12);
            padding: 17px 50px;
            @media screen and (max-width: $tableWidth) {
                padding: 12px 32px;
            }
            border-color: $mainFontColor;
        }
    }
}
