@import '../../utils/vars';

.tooltip-text {
  cursor: pointer;

  &__react-tooltip {
    width: 190px;
    font-family: $mainFont;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $primaryTextColor;
    opacity: 1;
    filter: drop-shadow(0px 4px 15px rgba(222, 187, 36, 0.4));

    &.show {
      background: black;
      opacity: 1 !important;
    }
  }
}

// Фикс, позволяющий сделать ссылку в тултипе кликабельной
// https://github.com/wwayne/react-tooltip/issues/336
.__react_component_tooltip {
  pointer-events: auto !important;
}