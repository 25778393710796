.dm-sidebar {
    position: relative;
    width: 218px;

    margin-top: -30px;
    margin-left: auto;
    padding: 29px 12px;

    background: $panelBgColor;
    border: 2px solid $mainBorderColor;
}
