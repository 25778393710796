.dm-input {
    appearance: none;
    padding: 12px;
    background-color: black;
    color: $primaryTextColor;
    border: 1px solid $darkYellow;
    border-radius: 2px;

    &[type='number']::-webkit-outer-spin-button,
    &[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:disabled {
        background: #000000;
        border: 1px solid #555038;
        color: #7E7E7E;
        user-select: none;
        pointer-events: none;
    }
}
