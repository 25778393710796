.header {
    position: relative;
    overflow: hidden;
    height: 120px;
    z-index: 999;

    @media screen and (max-width: 1024px) {
        background: rgba(85, 80, 56, 0.2);
    }

    @media screen and (max-width: $tableWidth) {
        height: 76px;
    }

    &__left {
        display: flex;
        align-items: flex-start;
        height: 100%;
        padding: 10px 0 0 40px;
        min-width: 451px;
        background: url('../../img/bg/header-left-bg.svg') no-repeat;

        @media screen and (max-width: 1024px) {
            min-width: auto;
            padding: 12px 20px;
        }

        @media screen and (max-width: $tableWidth) {
            background: none;
        }
    }

    &__middle {
        background-color: #fff;
        flex: 1 0 55px;
        height: 43px;
        background: rgba(85, 80, 56, 0.2);
        border: 2px solid #555038;
        border-left: 0;
        border-right: 0;

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }

    &__right {
        padding: 15px 30px 40px;
        min-width: 446px;
        background: url('../../img/bg/header-right-bg.svg') 100% 0 no-repeat;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex: 0 1 446px;

        @media screen and (max-width: 1024px) {
            min-width: auto;
            padding: 12px 16px;
        }

        @media screen and (max-width: $tableWidth) {
            background: none;
        }
    }

    &--with-sidebar &__right {
        min-width: 502px;
        background: url('../../img/bg/header-right-big-bg.svg') 100% 0 no-repeat;

        @media screen and (max-width: 1024px) {
            min-width: auto;
            background: none;
        }
    }

    @media screen and (max-width: $tableWidth) {
        @include blur-block;
        height: auto;
        overflow: unset;
    }

    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        z-index: 69;

        @media screen and (max-width: $tableWidth) {
            height: 76px;
        }
    }
}

.header-logo {
    display: flex;
    padding-right: 14px;

    &__img {
        width: 50px;
        height: 50px;
        fill: $mainFontColor;

        margin-right: 12px;

        @media screen and (max-width: $tableWidth) {
            width: 26px;
            height: 34px;
        }
    }

    &__btn {
        background: none;
        border: none;
        cursor: pointer;
    }

    &__dm {
        @media screen and (max-width: $tableWidth) {
            display: none;
        }
    }
}

.header_wax__wrapper {
    display: flex;
    align-items: center;

    .block_sum {
        margin-right: 24px;

        @media screen and (max-width: $tableWidth) {
            display: flex;
            position: absolute;
            left: 15px;
            right: 15px;
            bottom: 8px;
            margin-right: 0;
        }
    }

    .dmp_sum {
        margin-bottom: 5px;

        @media screen and (max-width: $tableWidth) {
            margin-right: 5px;
        }
    }

    .dmp_sum,
    .wax_sum {
        width: 100%;
        display: flex;
        align-items: center;
        color: $primaryTextColor;

        @media screen and (max-width: $tableWidth) {
            justify-content: center;
            margin-bottom: 0;
            overflow: hidden;
        }

        .icon {
            width: 20px;
            height: 20px;
            margin-right: 5px;
            margin-bottom: 2px;
            flex-shrink: 0;

            @media screen and (max-width: 1920px) {
                width: 15px;
                height: 15px;
            }

            svg {
                width: 100%;
                height: auto;
            }
        }

        .sum {
            font-size: px(12);
            line-height: 14px;
            margin-right: 5px;

            @media screen and (max-width: $tableWidth) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                min-width: 0;
            }
        }

        .type {
            margin-left: auto;
            font-size: px(12);
            line-height: 14px;

            @media screen and (max-width: $tableWidth) {
                margin-left: 0;
            }
        }
    }

    .header_wax {
        font-size: px(12);
        cursor: pointer;
        color: $accentColor;

        @media screen and (max-width: $tableWidth) {
            font-size: px(14);

            .price {
                display: none;
            }
        }

        a {
            color: $mainFontColor;

            @media screen and (max-width: $phoneWidth) {
                max-width: 70px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                vertical-align: middle;
            }
        }

        .wax-logo {
            width: 52px;
            height: 52px;
            &.auth {
                width: 25px;
                height: 25px;

                @media screen and (max-width: 2560px) {
                    width: 35px;
                    height: 35px;
                }

                @media screen and (max-width: 1920px) {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        .wax-logo {
            margin-right: 10px;

            @media screen and (max-width: $tableWidth) {
                width: 24px;
                height: 34px;
            }
        }

        img {
            width: 42px;
            height: 42px;
            margin-right: 10px;

            @media screen and (max-width: $tableWidth) {
                width: 24px;
                height: 34px;
                margin-right: 7px;
            }
        }

        .logout_btn {
            background: none;
            border: none;
            color: #f5df4d;
            padding-left: 0;

            transition: all 0.2s;
            @media screen and (max-width: $tableWidth) {
                padding: 0;
            }

            svg {
                margin-right: 2px;
                margin-bottom: 3px;
                margin-left: 5px;

                width: 15px;
                height: 16.5px;

                @media screen and (max-width: 2560px) {
                    height: 19.5px;
                }

                @media screen and (max-width: 1920px) {
                    height: 16.5px;
                }

                @media screen and (max-width: $tableWidth) {
                    width: 15px;
                    height: 12.5px;
                    margin-right: 0;
                }
            }

            &:hover {
                color: $mainFontColor;
            }
        }
    }
}
