.dm-empty-screen {
    display: grid;
    place-items: center;
    align-content: center;
    height: 100%;

    &__container {
        text-align: center;
        display: grid;
        place-items: center;
    }

    &__icon {
        margin-bottom: 20px;
        color: $mainFontColor;
        fill: currentColor;
        stroke: currentColor;
    }

    &__title {
        color: $mainFontColor;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 14px;
    }

    &__content {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
}
