.status-bar {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px 0;
    margin-top: 37px;
    margin: 10px auto 0;
    position: relative;

    background: url('../../img/bg/status-bar-bg.svg') 0 0 / 100% 100% no-repeat;

    @media screen and (max-width: $tableWidth) {
        display: none;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);

        @media screen and (max-width: $tableWidth) {
            width: 100%;
            justify-content: space-around;
        }

        .title {
            color: #aa9710;
            font-size: px(12);
            text-shadow: none;
            font-weight: 400;
            line-height: 14px;
        }

        color: $mainFontColor;
        font-weight: 600;

        .status_item {
            padding-right: 40px;
            padding-left: 40px;
            border-right: 1px solid $mainFontColor;

            @media screen and (max-width: 1920px) {
                padding-right: 20px;
                padding-left: 20px;
            }

            &:last-child {
                border: none;
            }
            @media screen and (max-width: $tableWidth) {
                width: 27%;
                font-weight: normal;
            }
        }
    }
}

.status-bar__mobile {
    display: none;
    @media screen and (max-width: $tableWidth) {
        display: block;
    }
    width: 100%;
    background: rgba(97, 157, 183, 0.1);

    &__container {
        .btn {
            font-size: px(12);
            letter-spacing: 0.14em;
            font-weight: 600;
            text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
            padding: 10px 30px;
        }
    }

    &.nft {
        margin-top: 18px;
        .status-bar__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 9px 0;
        }

        .left__block,
        .right__block {
            width: 50%;
        }

        .left__block {
            border-right: 1px solid $mainFontColor;
        }

        .status_item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-right: none;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .title {
            font-size: px(14);
            line-height: 14px;
            color: #aa9710;
        }

        .value {
            font-weight: 600;
            font-size: px(14);
            line-height: 14px;
            color: #f5df4d;
            text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
        }
    }

    &.rewards {
        margin-top: 14px;
        border-top: 1px solid rgba(245, 223, 77, 0.2);
        border-bottom: 1px solid rgba(245, 223, 77, 0.2);
        background: none;

        .status-bar__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
        }

        .status_item {
            border: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 40%;
        }

        .title {
            font-size: px(14);
            line-height: 14px;
            color: #aa9710;
        }

        .value {
            font-weight: 600;
            font-size: px(14);
            line-height: 14px;
            color: #f5df4d;
            text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
        }
    }
}
