.affiliate-modal-auth-error {
    padding: 24px 12px 36px 12px;
    text-align: center;

    &__title {
        padding-top: 24px;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;

        text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
    }
    &__description {
        padding-top: 4px;
        font-size: 24px;
        line-height: 28px;

        text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
    }

    &__button {
        width: 220px;
        margin: 32px auto 0 auto;
    }
}