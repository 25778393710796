.staking_popup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100vh;
    height: 100%;
    background-color: rgba(17, 16, 10, .8);
    padding: 20px 16px;
    backdrop-filter: blur(6px);
    z-index: 100;
    transform: translateY(-100%);
    transition: transform .2s ease-in;

    .staking_container {
        width: 100%;
        // height: 90vh;
        height: 90%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &.active {
        transform: translateY(0);
    }

    .close_modal {
        display: flex;

        button {
            margin-left: auto;
            background: none;
            border: none;
            color: $mainFontColor;
            font-size: 16px;

            span {
                font-weight: 800;
                font-size: 17px;
            }
        }
    }

    .block_title {
        font-size: 16px;
        letter-spacing: 0.05em;
        margin-bottom: 20px;
        position: relative;

        .info_icon {
            position: absolute;
            top: -9px;
            width: 20px;
            height: 20px;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .info_popup {
            display: none;
            position: absolute;
            top: 70px;
            left: 0;
            width: 58%;
            background: #000;
            z-index: 9999;
            box-sizing: border-box;
            padding: 12px 12px 30px 12px;
            font-size: 12px;
            line-height: 1.2;
            color: #F5DF4D;
            line-height: 12px;
            // filter: drop-shadow(0px 2px 10px rgba(245, 223, 77, 0.3));
            box-shadow: 0px 2px 10px rgba(245, 223, 77, 0.3);
            text-align: left;
            font-weight: 400;
            letter-spacing: 0;

            @media screen and (max-width: 1920px) {
                top: 53px;
            }

            @media screen and (max-width: $tableWidth) {
                left: 28px;
            }

            &.show {
                display: block;
            }

            &::before {
                content: '';
                position: absolute;
                border: 30px solid transparent;
                border-bottom: 20px solid rgb(0, 0, 0);
                display: block;
                width: 0;
                height: 0;
                top: -50px;
                left: 0;
                filter: drop-shadow(0px 2px 10px rgba(245, 223, 77, 0.3));
                // box-shadow: 0px 2px 10px rgba(245, 223, 77, 0.3);
                z-index: 9998;
                mix-blend-mode: overlay;
            }
        }
    }
    .postscript {
        font-size: px(10);
    }

    .information_item {
        padding: 16px 0;
        font-size: 12px;

        .information_title {
            font-size: px(16);

            .value {
                font-size: px(14);
            }

            .icon {
                width: 12px;
                height: 12px;
                margin-bottom: 4px;
                margin-right: 5px;
            }

            .info_block {
                right: -20px;
            }
        }



        &:last-child {
            border-bottom: none;
        }

        .information_value {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;

            &.information__info {
                padding-right: 40px;
            }

            .icon {
                width: 12px;
                height: 12px;
                margin-bottom: 4px;
            }
        }

        .information__content {
            .item {
                .value {
                    padding-right: 40px;
                }
            }
        }
    }

    .block_information .information_item .postscript {
        font-size: 10px;

    }

    .earned_rewards .block_information .information_item {
        .information_value {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
        }
    }

    .earned_rewards {
        margin-top: 60px;
        margin-bottom: 34px;

        .block_title {
            margin-bottom: 6px;
        }

        .value {
            font-size: 16px;
        }
    }

    .referal_link {
        padding-top: 23px;
        border-top: 1px solid rgba(245, 223, 77, 0.3);
    }

    .button_block {
        display: flex;
        justify-content: center;
        margin-top: 40px;

        .btn {
            padding: 10px 48px;
            font-size: px(12);
            color: $mainFontColor;
        }
    }

    .block_link {
        position: relative;
        z-index: 69;
        margin: 19px 0;
        font-size: 10px;
        display: flex;
        align-items: center;

        button {
            cursor: pointer;
            background: none;
            border: none;
        }
        a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 62%;
            display: block;
            margin-right: 10px;
        }
    }
}