.store-page {
    height: 100%;
    display: flex;
    flex-direction: column;

    &-filters {
        &__title {
            @media screen and (max-width: $tableWidth) {
                display: none;
            }
        }

        &__controls {
            margin-top: 10px;
            display: flex;
            gap: 20px;

            @media screen and (max-width: $tableWidth) {
                flex-direction: column;
                align-items: center;
            }
        }

        &__control {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 12px;
            line-height: 16px;
        }

        &__clear {
            margin-left: 60px;
        }
    }

    &__content {
        position: relative;
        height: 100%;
        min-height: 0;
        display: flex;
        flex-direction: column;
        padding: 0 28px 28px 42px;
        margin-top: 26px;
        margin-left: -10px;

        @media screen and (max-width: $tableWidth) {
            margin-left: 0;
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    &__list {
        margin-top: 16px;
        margin-right: -10px;
        padding-right: 10px;
        height: 100%;
        overflow-y: auto;
    }
}

.store-cards-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 180px));
    gap: 15px;

    @media screen and (max-width: $tableWidth) {
        grid-template-columns: repeat(auto-fit, minmax(145px, 147px));
    }
}

.store-card {
    cursor: pointer;
    position: relative;
    background-color: rgba(84, 84, 102, 0.12);
    color: #fff;

    &:hover {
        color: #fff;
    }

    &--restriction {
        .store-card__preview img {
            filter: grayscale(0.9);
        }
    }

    &__out-stock {
        position: absolute;
        top: 86px;
        left: 0;
        right: 0;

        display: flex;
        align-items: center;
        width: 78px;
        height: 78px;
        margin: 0 auto;

        border-radius: 50%;
        background-color: #e4e4e4;

        font-weight: 800;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #7e7e7e;

        z-index: 1;
    }

    &__badge {
        position: absolute;
        top: 2px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }

    &__restriction {
        position: absolute;
        top: 130px;
        left: 4px;
        right: 4px;

        height: 20px;
        padding: 2px 8px;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #272727;
        background: #acacac;
        z-index: 1;
    }

    &__preview {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 249px;
        background-color: #272727;
        border: 1px solid #545466;

        @media screen and (max-width: $tableWidth) {
            min-height: 205px;
        }
    }

    &__info {
        position: absolute;
        right: 10px;
        bottom: 11px;
        cursor: pointer;
    }

    &__bottom {
        padding: 4px 7px 7px;
        margin: 0 1px;
        background-color: rgba(84, 84, 102, 0.12);
        border: 1px solid #545466;
        border-top: 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin-bottom: 14px;
    }

    &__title {
        margin-bottom: 4px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 800;
    }

    &__price {
        margin-bottom: 5.5px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        color: #f78e1d;
    }

    &__left {
        font-size: 12px;
        line-height: 16px;
        color: #acacac;
    }

    & &__button {
        width: 100%;
        padding: 7px 2px;
    }

    &--wide {
        display: flex;
        width: 100%;
        min-width: 472px;
        max-width: 620px;

        .store-card__image-container {
            position: relative;
            width: 180px;
        }
        .store-card__bottom {
            position: relative;
            flex: 1;
            padding: 20px 16px 16px 20px;

            .store-card {
                &__title {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 28px;
                    margin-bottom: 9px;
                }

                &__action {
                    width: 218px;
                    position: absolute;
                    right: 16px;
                    bottom: 16px;
                }
            }
        }
    }
}

.store-card-counter {
    position: relative;
    display: flex;
    gap: 6px;

    &__buy {
        position: absolute;
        top: 100%;
        left: -10px;
        right: -10px;

        margin-top: 7px;
        padding: 10px 4px;

        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
        color: #1a2327;
        text-align: center;

        border: 3px solid #272727;
        border-radius: 4px;
        background-color: #f5df4d;

        cursor: pointer;
        z-index: 1;

        svg {
            position: absolute;
            top: -10px;
            left: calc(50% - 18px);
        }

        &:hover {
            background-color: #e0cc47;

            svg path {
                fill: #e0cc47;
            }
        }
    }

    .dm-input {
        width: 50px;
        padding: 6px 10px;
        text-align: center;
    }

    .dm-button {
        width: 50px;
        padding: 6px 16px;
    }
}

.card-tooltip {
    .store-card &__react-tooltip {
        display: flex;
        opacity: 1 !important;
        gap: 16px;
        min-width: 378px;
        width: min-content;
        padding: 16px;
        background-color: #272727;
        border: 1px solid #545466;
        border-radius: 0;
    }

    &__list {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 12px;
    }

    &__content-list {
        flex: 1;
    }

    &__list-item {
        display: flex;
        flex-direction: column;
    }

    &__title {
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 16px;
        color: #acacac;
    }

    &__text {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #e4e4e4;
    }

    &__text-secondary {
        font-size: 12px;
        line-height: 16px;
        color: #acacac;
    }
}

.store-product-page {
    &-main {
        display: flex;
        height: 100%;
        gap: 48px;
        margin-top: 28px;

        @media screen and (max-width: $tableWidth) {
            flex-direction: column;
            gap: 40px;
        }
    }

    &-info {
        padding: 24px 22px 38px 22px;
        background-color: rgba(63, 87, 97, 0.2);
        max-width: 220px;

        @media screen and (max-width: $tableWidth) {
            max-width: 450px;
            align-self: center;
        }

        &__title {
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
        }

        &__card {
            max-width: 176px;
            margin: 12px auto 0;
            cursor: auto;
        }

        &-description {
            &__title {
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                margin-top: 24px;
            }

            &__text {
                font-size: 12px;
                line-height: 16px;
                color: #acacac;
                margin-top: 8px;
            }
        }
    }

    &-options {
        @media screen and (max-width: $tableWidth) {
            padding-bottom: 40px;
        }

        &-grid {
            display: grid;
            grid-template-columns: max-content max-content max-content;
            gap: 20px 28px;
            padding-bottom: 16px;
            margin-bottom: 24px;
            border-bottom: 1px solid rgba(63, 87, 97, 0.2);

            @media screen and (max-width: $tableWidth) {
                grid-template-columns: max-content max-content;
            }
        }
        &-cell {
            display: flex;
            flex-direction: column;
            gap: 4px;

            & > p {
                font-size: 14px;
                line-height: 16px;
                color: #acacac;
            }
            & > span {
                font-size: 16px;
                line-height: 20px;
            }
        }
        &__price-block {
            display: flex;
            align-items: center;
            gap: 4px;
        }
        &__price {
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            color: #f78e1d;

            &.vdmp {
                color: $mainFontColor;
            }

            &.wax {
                color: #f78e1d;
            }
        }

        &__time {
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
        }

        &__notice {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 16px;
        }

        &-form {
            display: flex;
            gap: 20px;
        }

        &-counter {
            display: flex;
            gap: 8px;

            .dm-input {
                width: 48px;
                padding: 6px 10px;
                text-align: center;
            }

            .dm-button {
                font-size: 16px;
                width: 46px;
                padding: 6px 16px;
            }
        }
    }
}
